let globalFederationError: string | null = null;

export function captureFederationErrorDescription() {
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get("error_description");
  if (error) {
    globalFederationError = error;
    urlParams.delete("error_description");
    window.history.replaceState({}, "", `\${window.location.pathname}?\${urlParams}`);
  }
}

export function getFederationErrorDescription(): string | null {
  return globalFederationError;
}

export function clearFederationErrorDescription() {
  globalFederationError = null;
}

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-west-1",
  aws_cognito_region: "eu-west-1",
  aws_user_pools_id: "eu-west-1_UAIL1qUwM",
  aws_user_pools_web_client_id: "1bi37g39tuti5f5r6ji67qmomg",
  oauth: {
    domain: "auth.myexamslab.com",
    scope: ["email", "profile", "openid"],
    redirectSignIn: "https://myexamslab.com/",
    redirectSignOut: "https://myexamslab.com/",
    responseType: "code",
    logoutUri: "https://myexamslab.com/",
  },
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: "6",
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;

/* tslint:disable */
/* eslint-disable */
/**
 * English Exams Lab Backend API
 * API of English Exams Lab backend
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: francis.englishexamslab@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityType = {
    PracticeExercises: 'PracticeExercises',
    PracticeTests: 'PracticeTests',
    MockExams: 'MockExams'
} as const;

export type ActivityType = typeof ActivityType[keyof typeof ActivityType];


/**
 * 
 * @export
 * @interface AddClassroomMemberRequest
 */
export interface AddClassroomMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AddClassroomMemberRequest
     */
    'UserId': string;
    /**
     * 
     * @type {Role}
     * @memberof AddClassroomMemberRequest
     */
    'Role': Role;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AnswerCorrection = {
    Correct: 'Correct',
    PartlyCorrect: 'PartlyCorrect',
    Incorrect: 'Incorrect'
} as const;

export type AnswerCorrection = typeof AnswerCorrection[keyof typeof AnswerCorrection];


/**
 * 
 * @export
 * @interface AssignLicenseRequest
 */
export interface AssignLicenseRequest {
    /**
     * 
     * @type {LicenseType}
     * @memberof AssignLicenseRequest
     */
    'LicenseType': LicenseType;
    /**
     * 
     * @type {LicenseDuration}
     * @memberof AssignLicenseRequest
     */
    'LicenseDuration': LicenseDuration;
    /**
     * 
     * @type {string}
     * @memberof AssignLicenseRequest
     */
    'AssigneeId': string;
    /**
     * 
     * @type {Role}
     * @memberof AssignLicenseRequest
     */
    'AssigneeRole': Role;
}


/**
 * 
 * @export
 * @interface AverageMockExamScore
 */
export interface AverageMockExamScore {
    /**
     * 
     * @type {OverallTestScoreTestScoreValue}
     * @memberof AverageMockExamScore
     */
    'CambridgeScore'?: OverallTestScoreTestScoreValue;
    /**
     * 
     * @type {Grade}
     * @memberof AverageMockExamScore
     */
    'Grade'?: Grade;
    /**
     * 
     * @type {CEFR}
     * @memberof AverageMockExamScore
     */
    'CEFRLevel'?: CEFR;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const BlockedExercisesLevelStatus = {
    Blocked: 'Blocked',
    Unblocked: 'Unblocked',
    PartiallyBlocked: 'PartiallyBlocked'
} as const;

export type BlockedExercisesLevelStatus = typeof BlockedExercisesLevelStatus[keyof typeof BlockedExercisesLevelStatus];


/**
 * 
 * @export
 * @interface BlockedExercisesValue
 */
export interface BlockedExercisesValue {
    /**
     * 
     * @type {BlockedExercisesLevelStatus}
     * @memberof BlockedExercisesValue
     */
    'Status': BlockedExercisesLevelStatus;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: Array<number>; }; }}
     * @memberof BlockedExercisesValue
     */
    'BlockedExercises'?: { [key: string]: { [key: string]: Array<number>; }; };
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CEFR = {
    C2: 'C2',
    C1: 'C1',
    B2: 'B2',
    B1: 'B1',
    A2: 'A2',
    A1: 'A1'
} as const;

export type CEFR = typeof CEFR[keyof typeof CEFR];


/**
 * 
 * @export
 * @interface Classroom
 */
export interface Classroom {
    /**
     * 
     * @type {string}
     * @memberof Classroom
     */
    'ClassroomId': string;
    /**
     * 
     * @type {string}
     * @memberof Classroom
     */
    'Name': string;
    /**
     * 
     * @type {boolean}
     * @memberof Classroom
     */
    'AccessToCorrectAnswers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Classroom
     */
    'ExerciseRepetition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Classroom
     */
    'AccessToSpeakingTranscripts'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Classroom
     */
    'AccessToPracticeTests'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Classroom
     */
    'AccessToFeedback'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Classroom
     */
    'PausePracticeTests'?: boolean;
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof Classroom
     */
    'AccessToMockExams'?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof Classroom
     */
    'AccessToMockExamResults'?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {Array<Level>}
     * @memberof Classroom
     */
    'AccessToLevels'?: Array<Level>;
    /**
     * 
     * @type {{ [key: string]: BlockedExercisesValue; }}
     * @memberof Classroom
     */
    'BlockedExercises'?: { [key: string]: BlockedExercisesValue; };
    /**
     * 
     * @type {boolean}
     * @memberof Classroom
     */
    'AllowExtraTimeExam'?: boolean;
}
/**
 * 
 * @export
 * @interface ClassroomMember
 */
export interface ClassroomMember {
    /**
     * 
     * @type {string}
     * @memberof ClassroomMember
     */
    'UserId': string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomMember
     */
    'Name': string;
    /**
     * 
     * @type {Role}
     * @memberof ClassroomMember
     */
    'Role': Role;
    /**
     * 
     * @type {Array<LicenseHistoryItem>}
     * @memberof ClassroomMember
     */
    'LicenseHistory': Array<LicenseHistoryItem>;
}


/**
 * 
 * @export
 * @interface ClassroomMockExam
 */
export interface ClassroomMockExam {
    /**
     * 
     * @type {string}
     * @memberof ClassroomMockExam
     */
    'MockExamId': string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomMockExam
     */
    'UserId': string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomMockExam
     */
    'UserName': string;
    /**
     * 
     * @type {Level}
     * @memberof ClassroomMockExam
     */
    'Level': Level;
    /**
     * 
     * @type {number}
     * @memberof ClassroomMockExam
     */
    'Number': number;
    /**
     * 
     * @type {string}
     * @memberof ClassroomMockExam
     */
    'Started': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClassroomMockExam
     */
    'Finished': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: MockExamSectionStatus; }}
     * @memberof ClassroomMockExam
     */
    'Status': { [key: string]: MockExamSectionStatus; };
    /**
     * 
     * @type {{ [key: string]: MockExamCambridgeScoreValue; }}
     * @memberof ClassroomMockExam
     */
    'CambridgeScore': { [key: string]: MockExamCambridgeScoreValue; };
    /**
     * 
     * @type {TotalClassroomMockExamScore}
     * @memberof ClassroomMockExam
     */
    'Total': TotalClassroomMockExamScore | null;
}


/**
 * 
 * @export
 * @interface ClassroomMockExamsListResponse
 */
export interface ClassroomMockExamsListResponse {
    /**
     * 
     * @type {Array<ClassroomMockExam>}
     * @memberof ClassroomMockExamsListResponse
     */
    'ClassroomMockExamsList': Array<ClassroomMockExam>;
    /**
     * Next Token for paginated results
     * @type {string}
     * @memberof ClassroomMockExamsListResponse
     */
    'NextToken': string | null;
}
/**
 * 
 * @export
 * @interface ClassroomPracticeTest
 */
export interface ClassroomPracticeTest {
    /**
     * 
     * @type {string}
     * @memberof ClassroomPracticeTest
     */
    'PracticeTestId': string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomPracticeTest
     */
    'UserId': string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomPracticeTest
     */
    'UserName': string;
    /**
     * 
     * @type {Level}
     * @memberof ClassroomPracticeTest
     */
    'Level': Level;
    /**
     * 
     * @type {Section}
     * @memberof ClassroomPracticeTest
     */
    'Section': Section;
    /**
     * 
     * @type {PracticeTestStatus}
     * @memberof ClassroomPracticeTest
     */
    'Status': PracticeTestStatus;
    /**
     * 
     * @type {string}
     * @memberof ClassroomPracticeTest
     */
    'Finished': string;
    /**
     * 
     * @type {number}
     * @memberof ClassroomPracticeTest
     */
    'TimeSpent': number;
    /**
     * 
     * @type {{ [key: string]: MockExamCambridgeScoreValue; }}
     * @memberof ClassroomPracticeTest
     */
    'TestScore': { [key: string]: MockExamCambridgeScoreValue; };
    /**
     * 
     * @type {{ [key: string]: MockExamCambridgeScoreValue; }}
     * @memberof ClassroomPracticeTest
     */
    'CambridgeScore': { [key: string]: MockExamCambridgeScoreValue; };
}


/**
 * 
 * @export
 * @interface ClassroomPracticeTestsListResponse
 */
export interface ClassroomPracticeTestsListResponse {
    /**
     * 
     * @type {Array<ClassroomPracticeTest>}
     * @memberof ClassroomPracticeTestsListResponse
     */
    'ClassroomPracticeTestsList': Array<ClassroomPracticeTest>;
    /**
     * Next Token for paginated results
     * @type {string}
     * @memberof ClassroomPracticeTestsListResponse
     */
    'NextToken': string | null;
}
/**
 * 
 * @export
 * @interface ClassroomSubmission
 */
export interface ClassroomSubmission {
    /**
     * 
     * @type {string}
     * @memberof ClassroomSubmission
     */
    'SubmissionId': string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomSubmission
     */
    'UserId': string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomSubmission
     */
    'UserName': string;
    /**
     * 
     * @type {Level}
     * @memberof ClassroomSubmission
     */
    'ExerciseLevel': Level;
    /**
     * 
     * @type {Section}
     * @memberof ClassroomSubmission
     */
    'ExerciseSection': Section;
    /**
     * 
     * @type {Part}
     * @memberof ClassroomSubmission
     */
    'ExercisePart': Part;
    /**
     * 
     * @type {number}
     * @memberof ClassroomSubmission
     */
    'ExerciseNumber': number;
    /**
     * 
     * @type {string}
     * @memberof ClassroomSubmission
     */
    'CreatedAt': string;
    /**
     * 
     * @type {ExerciseTestScore}
     * @memberof ClassroomSubmission
     */
    'TestScore': ExerciseTestScore;
    /**
     * 
     * @type {string}
     * @memberof ClassroomSubmission
     */
    'PracticeTestId': string | null;
}


/**
 * 
 * @export
 * @interface ClassroomSubmissionsListResponse
 */
export interface ClassroomSubmissionsListResponse {
    /**
     * 
     * @type {Array<ClassroomSubmission>}
     * @memberof ClassroomSubmissionsListResponse
     */
    'ClassroomSubmissionsList': Array<ClassroomSubmission>;
    /**
     * Next Token for paginated results
     * @type {string}
     * @memberof ClassroomSubmissionsListResponse
     */
    'NextToken': string | null;
}
/**
 * @type CorrectAnswersListOfLists
 * @export
 */
export type CorrectAnswersListOfLists = Array<string> | string;

/**
 * 
 * @export
 * @interface Correction
 */
export interface Correction {
    /**
     * 
     * @type {string}
     * @memberof Correction
     */
    'SubmissionId': string;
    /**
     * 
     * @type {string}
     * @memberof Correction
     */
    'UserId': string;
    /**
     * 
     * @type {string}
     * @memberof Correction
     */
    'UserName': string;
    /**
     * 
     * @type {Level}
     * @memberof Correction
     */
    'Level': Level;
    /**
     * 
     * @type {Part}
     * @memberof Correction
     */
    'Part': Part;
    /**
     * 
     * @type {number}
     * @memberof Correction
     */
    'Number': number;
    /**
     * 
     * @type {string}
     * @memberof Correction
     */
    'Date': string;
    /**
     * 
     * @type {ActivityType}
     * @memberof Correction
     */
    'ActivityType': ActivityType;
    /**
     * 
     * @type {string}
     * @memberof Correction
     */
    'PracticeTestId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Correction
     */
    'MockExamNumber'?: number | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CorrectionType = {
    Writing: 'Writing',
    Speaking: 'Speaking'
} as const;

export type CorrectionType = typeof CorrectionType[keyof typeof CorrectionType];


/**
 * 
 * @export
 * @interface CorrectionsListResponse
 */
export interface CorrectionsListResponse {
    /**
     * 
     * @type {Array<Correction>}
     * @memberof CorrectionsListResponse
     */
    'CorrectionsList': Array<Correction>;
    /**
     * Next Token for paginated results
     * @type {string}
     * @memberof CorrectionsListResponse
     */
    'NextToken': string | null;
}
/**
 * 
 * @export
 * @interface CountClassroomCorrectionsValue
 */
export interface CountClassroomCorrectionsValue {
    /**
     * 
     * @type {string}
     * @memberof CountClassroomCorrectionsValue
     */
    'ClassroomName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CountClassroomCorrectionsValue
     */
    'SpeakingCorrections'?: number;
    /**
     * 
     * @type {number}
     * @memberof CountClassroomCorrectionsValue
     */
    'WritingCorrections'?: number;
}
/**
 * 
 * @export
 * @interface CreateClassroomRequest
 */
export interface CreateClassroomRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateClassroomRequest
     */
    'Name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClassroomRequest
     */
    'AccessToCorrectAnswers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClassroomRequest
     */
    'ExerciseRepetition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClassroomRequest
     */
    'AccessToSpeakingTranscripts'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClassroomRequest
     */
    'AccessToPracticeTests'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClassroomRequest
     */
    'AccessToFeedback'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClassroomRequest
     */
    'PausePracticeTests'?: boolean;
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof CreateClassroomRequest
     */
    'AccessToMockExams'?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof CreateClassroomRequest
     */
    'AccessToMockExamResults'?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {Array<Level>}
     * @memberof CreateClassroomRequest
     */
    'AccessToLevels'?: Array<Level>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClassroomRequest
     */
    'AllowExtraTimeExam'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateDistributorUserRequest
 */
export interface CreateDistributorUserRequest {
    /**
     * 
     * @type {DistributorUserRole}
     * @memberof CreateDistributorUserRequest
     */
    'Role': DistributorUserRole;
    /**
     * 
     * @type {string}
     * @memberof CreateDistributorUserRequest
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDistributorUserRequest
     */
    'Email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDistributorUserRequest
     */
    'Region'?: string;
}


/**
 * 
 * @export
 * @interface CreateFeedbackRequest
 */
export interface CreateFeedbackRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackRequest
     */
    'FeedbackText': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackRequest
     */
    'ExerciseId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateFeedbackRequest
     */
    'QuestionIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackRequest
     */
    'Answer'?: string;
}
/**
 * 
 * @export
 * @interface CreateOrderRequest
 */
export interface CreateOrderRequest {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof CreateOrderRequest
     */
    'Licenses': { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'SchoolId'?: string;
}
/**
 * @type CreatePayment200Response
 * @export
 */
export type CreatePayment200Response = Payment | PaymentIntent;

/**
 * 
 * @export
 * @interface CreatePaymentRequest
 */
export interface CreatePaymentRequest {
    /**
     * 
     * @type {PaymentMethod}
     * @memberof CreatePaymentRequest
     */
    'PaymentMethod': PaymentMethod;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof CreatePaymentRequest
     */
    'Licenses': { [key: string]: { [key: string]: number; }; };
}


/**
 * 
 * @export
 * @interface CreatePracticeTestRequest
 */
export interface CreatePracticeTestRequest {
    /**
     * 
     * @type {Level}
     * @memberof CreatePracticeTestRequest
     */
    'Level': Level;
    /**
     * 
     * @type {Section}
     * @memberof CreatePracticeTestRequest
     */
    'Section': Section;
}


/**
 * 
 * @export
 * @interface CreateSchoolRequest
 */
export interface CreateSchoolRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSchoolRequest
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSchoolRequest
     */
    'Email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSchoolRequest
     */
    'AgentId'?: string;
}
/**
 * 
 * @export
 * @interface CreateSubmissionRequest
 */
export interface CreateSubmissionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSubmissionRequest
     */
    'ExerciseId': string;
    /**
     * 
     * @type {object}
     * @memberof CreateSubmissionRequest
     */
    'Answers': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSubmissionRequest
     */
    'Candidates'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'Email': string;
    /**
     * 
     * @type {Role}
     * @memberof CreateUserRequest
     */
    'Role': Role;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'Password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    'PermanentPassword'?: boolean;
    /**
     * 
     * @type {Array<Level>}
     * @memberof CreateUserRequest
     */
    'AccessToLevels'?: Array<Level>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRequest
     */
    'Classrooms'?: Array<string>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const Currency = {
    Eur: 'EUR',
    Usd: 'USD'
} as const;

export type Currency = typeof Currency[keyof typeof Currency];


/**
 * 
 * @export
 * @interface Distributor
 */
export interface Distributor {
    /**
     * 
     * @type {string}
     * @memberof Distributor
     */
    'DistributorId': string;
    /**
     * 
     * @type {string}
     * @memberof Distributor
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof Distributor
     */
    'Country': string;
    /**
     * 
     * @type {string}
     * @memberof Distributor
     */
    'Logo': string;
    /**
     * 
     * @type {string}
     * @memberof Distributor
     */
    'DistributorSubdomain': string;
    /**
     * 
     * @type {Currency}
     * @memberof Distributor
     */
    'Currency': Currency;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof Distributor
     */
    'Prices': { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof Distributor
     */
    'AvailableLicenses': { [key: string]: { [key: string]: number; }; };
}


/**
 * 
 * @export
 * @interface DistributorClient
 */
export interface DistributorClient {
    /**
     * 
     * @type {string}
     * @memberof DistributorClient
     */
    'SchoolId': string;
    /**
     * 
     * @type {string}
     * @memberof DistributorClient
     */
    'SchoolName': string;
    /**
     * 
     * @type {string}
     * @memberof DistributorClient
     */
    'Country': string;
    /**
     * 
     * @type {string}
     * @memberof DistributorClient
     */
    'Region': string;
    /**
     * 
     * @type {string}
     * @memberof DistributorClient
     */
    'AgentId': string;
    /**
     * 
     * @type {string}
     * @memberof DistributorClient
     */
    'AgentName': string;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DistributorClient
     */
    'Config': { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface DistributorClientsListResponse
 */
export interface DistributorClientsListResponse {
    /**
     * 
     * @type {Array<DistributorClient>}
     * @memberof DistributorClientsListResponse
     */
    'DistributorClientsList': Array<DistributorClient>;
    /**
     * Next Token for paginated results
     * @type {string}
     * @memberof DistributorClientsListResponse
     */
    'NextToken': string | null;
}
/**
 * 
 * @export
 * @interface DistributorUser
 */
export interface DistributorUser {
    /**
     * 
     * @type {string}
     * @memberof DistributorUser
     */
    'UserId': string;
    /**
     * 
     * @type {string}
     * @memberof DistributorUser
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof DistributorUser
     */
    'Email': string;
    /**
     * 
     * @type {DistributorUserRole}
     * @memberof DistributorUser
     */
    'Role': DistributorUserRole;
    /**
     * 
     * @type {string}
     * @memberof DistributorUser
     */
    'Region': string;
    /**
     * 
     * @type {string}
     * @memberof DistributorUser
     */
    'Created': string;
    /**
     * 
     * @type {boolean}
     * @memberof DistributorUser
     */
    'Active': boolean;
    /**
     * 
     * @type {string}
     * @memberof DistributorUser
     */
    'ProfilePicture': string;
}


/**
 * Distributor user role
 * @export
 * @enum {string}
 */

export const DistributorUserRole = {
    DistributorAdmin: 'DistributorAdmin',
    Agent: 'Agent'
} as const;

export type DistributorUserRole = typeof DistributorUserRole[keyof typeof DistributorUserRole];


/**
 * 
 * @export
 * @interface DistributorUsersListResponse
 */
export interface DistributorUsersListResponse {
    /**
     * 
     * @type {Array<DistributorUser>}
     * @memberof DistributorUsersListResponse
     */
    'DistributorUsersList': Array<DistributorUser>;
    /**
     * Next Token for paginated results
     * @type {string}
     * @memberof DistributorUsersListResponse
     */
    'NextToken': string | null;
}
/**
 * 
 * @export
 * @interface ExceptionResponse
 */
export interface ExceptionResponse {
    /**
     * HTTP error code
     * @type {number}
     * @memberof ExceptionResponse
     */
    'statusCode': number;
    /**
     * Response headers
     * @type {object}
     * @memberof ExceptionResponse
     */
    'headers': object;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ExceptionResponse
     */
    'body': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Exercise
 */
export interface Exercise {
    /**
     * 
     * @type {string}
     * @memberof Exercise
     */
    'ExerciseId': string;
    /**
     * 
     * @type {Level}
     * @memberof Exercise
     */
    'Level': Level;
    /**
     * 
     * @type {Section}
     * @memberof Exercise
     */
    'Section': Section;
    /**
     * 
     * @type {Part}
     * @memberof Exercise
     */
    'Part': Part;
    /**
     * 
     * @type {number}
     * @memberof Exercise
     */
    'Number': number;
    /**
     * 
     * @type {number}
     * @memberof Exercise
     */
    'Duration': number;
    /**
     * 
     * @type {boolean}
     * @memberof Exercise
     */
    'Active': boolean;
    /**
     * 
     * @type {object}
     * @memberof Exercise
     */
    'Attributes': object;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Exercise
     */
    'AIFeedback': { [key: string]: string; };
}


/**
 * 
 * @export
 * @interface ExerciseTestScore
 */
export interface ExerciseTestScore {
    /**
     * 
     * @type {number}
     * @memberof ExerciseTestScore
     */
    'TestScore': number;
    /**
     * 
     * @type {number}
     * @memberof ExerciseTestScore
     */
    'MaxScore': number;
    /**
     * 
     * @type {{ [key: string]: AnswerCorrection; }}
     * @memberof ExerciseTestScore
     */
    'CorrectionResults'?: { [key: string]: AnswerCorrection; };
    /**
     * 
     * @type {MarkingScheme}
     * @memberof ExerciseTestScore
     */
    'MarkingScheme'?: MarkingScheme;
}
/**
 * 
 * @export
 * @interface Feedback
 */
export interface Feedback {
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'FeedbackId': string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'ReporterId': string;
    /**
     * 
     * @type {Role}
     * @memberof Feedback
     */
    'ReporterRole': Role;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'FeedbackText': string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'ExerciseId': string;
    /**
     * 
     * @type {number}
     * @memberof Feedback
     */
    'QuestionIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'Answer'?: string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'CreatedAt': string;
}


/**
 * @type GetExerciseByIdExerciseIdLevelParameter
 * @export
 */
export type GetExerciseByIdExerciseIdLevelParameter = Level | string;

/**
 * 
 * @export
 * @interface GlobalStatistics
 */
export interface GlobalStatistics {
    /**
     * 
     * @type {SuccessRate}
     * @memberof GlobalStatistics
     */
    'SuccessRate': SuccessRate;
    /**
     * 
     * @type {ProgressRate}
     * @memberof GlobalStatistics
     */
    'ProgressRate': ProgressRate;
    /**
     * 
     * @type {GlobalStatisticsLatestExamMark}
     * @memberof GlobalStatistics
     */
    'LatestExamMark': GlobalStatisticsLatestExamMark | null;
}
/**
 * 
 * @export
 * @interface GlobalStatisticsLatestExamMark
 */
export interface GlobalStatisticsLatestExamMark {
    /**
     * 
     * @type {number}
     * @memberof GlobalStatisticsLatestExamMark
     */
    'CambridgeScore'?: number;
    /**
     * 
     * @type {Grade}
     * @memberof GlobalStatisticsLatestExamMark
     */
    'Grade'?: Grade;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const Grade = {
    GradeA: 'Grade A',
    GradeB: 'Grade B',
    GradeC: 'Grade C',
    GradeD: 'Grade D',
    LevelC2: 'Level C2',
    LevelC1: 'Level C1',
    LevelB2: 'Level B2',
    LevelB1: 'Level B1',
    LevelA2: 'Level A2',
    LevelA1: 'Level A1',
    Minus: '-'
} as const;

export type Grade = typeof Grade[keyof typeof Grade];


/**
 * 
 * @export
 * @interface HasCorrections
 */
export interface HasCorrections {
    /**
     * 
     * @type {boolean}
     * @memberof HasCorrections
     */
    'HasCorrections'?: boolean;
}
/**
 * 
 * @export
 * @interface ImportUser
 */
export interface ImportUser {
    /**
     * 
     * @type {string}
     * @memberof ImportUser
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof ImportUser
     */
    'Email': string;
    /**
     * 
     * @type {Role}
     * @memberof ImportUser
     */
    'Role': Role;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportUser
     */
    'ClassroomsList'?: Array<string>;
}


/**
 * Exercise level
 * @export
 * @enum {string}
 */

export const Level = {
    C2P: 'C2P',
    C1A: 'C1A',
    B2F: 'B2F',
    B2S: 'B2S',
    B1P: 'B1P',
    B1S: 'B1S',
    A2K: 'A2K',
    A2S: 'A2S',
    A2F: 'A2F',
    A1M: 'A1M',
    Pa1: 'PA1',
    All: 'ALL'
} as const;

export type Level = typeof Level[keyof typeof Level];


/**
 * 
 * @export
 * @interface License
 */
export interface License {
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'LicenseId': string;
    /**
     * 
     * @type {LicenseDuration}
     * @memberof License
     */
    'LicenseDuration': LicenseDuration;
    /**
     * 
     * @type {LicenseType}
     * @memberof License
     */
    'LicenseType': LicenseType;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'Created': string;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'AssigneeId': string | null;
    /**
     * 
     * @type {Role}
     * @memberof License
     */
    'AssigneeRole': Role;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'AssigneeName': string | null;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'Assigned': string | null;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'AssignedBy': string | null;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    'ValidUntil': string | null;
    /**
     * 
     * @type {LicenseStatus}
     * @memberof License
     */
    'LicenseStatus': LicenseStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const LicenseDuration = {
    M3: 'M3',
    M6: 'M6',
    M9: 'M9',
    M10: 'M10',
    M12: 'M12'
} as const;

export type LicenseDuration = typeof LicenseDuration[keyof typeof LicenseDuration];


/**
 * 
 * @export
 * @interface LicenseDurationAvailability
 */
export interface LicenseDurationAvailability {
    /**
     * 
     * @type {number}
     * @memberof LicenseDurationAvailability
     */
    'Total': number;
    /**
     * 
     * @type {number}
     * @memberof LicenseDurationAvailability
     */
    'Available': number;
}
/**
 * 
 * @export
 * @interface LicenseHistoryItem
 */
export interface LicenseHistoryItem {
    /**
     * 
     * @type {string}
     * @memberof LicenseHistoryItem
     */
    'LicenseId': string;
    /**
     * 
     * @type {LicenseDuration}
     * @memberof LicenseHistoryItem
     */
    'LicenseDuration': LicenseDuration;
    /**
     * 
     * @type {LicenseType}
     * @memberof LicenseHistoryItem
     */
    'LicenseType': LicenseType;
    /**
     * 
     * @type {string}
     * @memberof LicenseHistoryItem
     */
    'LicenseStarted': string;
    /**
     * 
     * @type {string}
     * @memberof LicenseHistoryItem
     */
    'ValidUntil': string;
}


/**
 * 
 * @export
 * @interface LicenseInvoice
 */
export interface LicenseInvoice {
    /**
     * 
     * @type {number}
     * @memberof LicenseInvoice
     */
    'Price': number;
    /**
     * 
     * @type {number}
     * @memberof LicenseInvoice
     */
    'Quantity': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LicenseStatus = {
    Purchased: 'Purchased',
    Assigned: 'Assigned'
} as const;

export type LicenseStatus = typeof LicenseStatus[keyof typeof LicenseStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const LicenseType = {
    Free: 'Free',
    AllExercises: 'AllExercises'
} as const;

export type LicenseType = typeof LicenseType[keyof typeof LicenseType];


/**
 * @type ListCorrections200Response
 * @export
 */
export type ListCorrections200Response = CorrectionsListResponse | HasCorrections | { [key: string]: CountClassroomCorrectionsValue; };

/**
 * 
 * @export
 * @interface MarkingScheme
 */
export interface MarkingScheme {
    /**
     * 
     * @type {number}
     * @memberof MarkingScheme
     */
    'CommunicativeAchievement'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarkingScheme
     */
    'Content'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarkingScheme
     */
    'DiscourseManagement'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarkingScheme
     */
    'GlobalAchievement'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarkingScheme
     */
    'GrammaticalResource'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarkingScheme
     */
    'InteractiveCommunication'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarkingScheme
     */
    'Language'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarkingScheme
     */
    'LexicalResource'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarkingScheme
     */
    'Organisation'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarkingScheme
     */
    'Pronunciation'?: number;
}
/**
 * 
 * @export
 * @interface MockExamCambridgeScoreValue
 */
export interface MockExamCambridgeScoreValue {
    /**
     * 
     * @type {number}
     * @memberof MockExamCambridgeScoreValue
     */
    'Score'?: number;
    /**
     * 
     * @type {number}
     * @memberof MockExamCambridgeScoreValue
     */
    'MaxScore'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MockExamSectionStatus = {
    ToBeCompleted: 'ToBeCompleted',
    Saved: 'Saved',
    ToBeCorrected: 'ToBeCorrected',
    EarlyFinished: 'EarlyFinished',
    Finished: 'Finished'
} as const;

export type MockExamSectionStatus = typeof MockExamSectionStatus[keyof typeof MockExamSectionStatus];


/**
 * 
 * @export
 * @interface MockExamSubmission
 */
export interface MockExamSubmission {
    /**
     * 
     * @type {string}
     * @memberof MockExamSubmission
     */
    'MockExamSubmissionId': string;
    /**
     * 
     * @type {string}
     * @memberof MockExamSubmission
     */
    'UserId': string;
    /**
     * 
     * @type {Level}
     * @memberof MockExamSubmission
     */
    'Level': Level;
    /**
     * 
     * @type {number}
     * @memberof MockExamSubmission
     */
    'Number': number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof MockExamSubmission
     */
    'Duration': { [key: string]: number; };
    /**
     * 
     * @type {string}
     * @memberof MockExamSubmission
     */
    'Started': string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof MockExamSubmission
     */
    'Exercises': { [key: string]: { [key: string]: string; }; };
    /**
     * 
     * @type {Array<string>}
     * @memberof MockExamSubmission
     */
    'Audios': Array<string>;
    /**
     * 
     * @type {MockExamSectionStatus}
     * @memberof MockExamSubmission
     */
    'Status': MockExamSectionStatus;
    /**
     * 
     * @type {string}
     * @memberof MockExamSubmission
     */
    'SelectedWriting': string | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof MockExamSubmission
     */
    'CompletedListeningParts'?: { [key: string]: boolean; };
    /**
     * 
     * @type {Array<string>}
     * @memberof MockExamSubmission
     */
    'Candidates': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MockExamSubmission
     */
    'CandidateMockExamSubmissionId': string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MockExamSubmission
     */
    'Finished': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: { [key: string]: string; }; }; }}
     * @memberof MockExamSubmission
     */
    'Answers': { [key: string]: { [key: string]: { [key: string]: string; }; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: Array<string>; }; }}
     * @memberof MockExamSubmission
     */
    'CorrectAnswers': { [key: string]: { [key: string]: Array<string>; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof MockExamSubmission
     */
    'TimeSpent': { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof MockExamSubmission
     */
    'Submissions': { [key: string]: { [key: string]: string; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: ExerciseTestScore; }; }}
     * @memberof MockExamSubmission
     */
    'TestScore': { [key: string]: { [key: string]: ExerciseTestScore; }; };
    /**
     * 
     * @type {OverallTestScore}
     * @memberof MockExamSubmission
     */
    'OverallMockExamScore': OverallTestScore | null;
    /**
     * 
     * @type {AverageMockExamScore}
     * @memberof MockExamSubmission
     */
    'AverageMockExamScore': AverageMockExamScore | null;
}


/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'OrderId': string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof Order
     */
    'OrderStatus': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'OrderCreated': string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof Order
     */
    'Licenses': { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'SchoolId': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'SchoolName': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'ReviewedBy'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderStatus = {
    Approved: 'Approved',
    Rejected: 'Rejected',
    Cancelled: 'Cancelled',
    Pending: 'Pending'
} as const;

export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


/**
 * 
 * @export
 * @interface OrdersListResponse
 */
export interface OrdersListResponse {
    /**
     * 
     * @type {Array<Order>}
     * @memberof OrdersListResponse
     */
    'OrdersList': Array<Order>;
    /**
     * Next Token for paginated results
     * @type {string}
     * @memberof OrdersListResponse
     */
    'NextToken': string | null;
}
/**
 * 
 * @export
 * @interface OverallTestScore
 */
export interface OverallTestScore {
    /**
     * 
     * @type {{ [key: string]: OverallTestScoreTestScoreValue; }}
     * @memberof OverallTestScore
     */
    'TestScore'?: { [key: string]: OverallTestScoreTestScoreValue; };
    /**
     * 
     * @type {{ [key: string]: OverallTestScoreTestScoreValue; }}
     * @memberof OverallTestScore
     */
    'CambridgeScore'?: { [key: string]: OverallTestScoreTestScoreValue; };
    /**
     * 
     * @type {{ [key: string]: Grade; }}
     * @memberof OverallTestScore
     */
    'Grade'?: { [key: string]: Grade; };
    /**
     * 
     * @type {{ [key: string]: CEFR; }}
     * @memberof OverallTestScore
     */
    'CEFRLevel'?: { [key: string]: CEFR; };
}
/**
 * 
 * @export
 * @interface OverallTestScoreTestScoreValue
 */
export interface OverallTestScoreTestScoreValue {
    /**
     * 
     * @type {number}
     * @memberof OverallTestScoreTestScoreValue
     */
    'Score'?: number;
    /**
     * 
     * @type {number}
     * @memberof OverallTestScoreTestScoreValue
     */
    'MaxScore'?: number;
}
/**
 * Part inside of the section
 * @export
 * @enum {string}
 */

export const Part = {
    P1: 'P1',
    P2: 'P2',
    P3: 'P3',
    P34: 'P34',
    P4: 'P4',
    P5: 'P5',
    P6: 'P6',
    P7: 'P7',
    P8: 'P8',
    P9: 'P9',
    P10: 'P10'
} as const;

export type Part = typeof Part[keyof typeof Part];


/**
 * 
 * @export
 * @interface PartStatistics
 */
export interface PartStatistics {
    /**
     * 
     * @type {SuccessRate}
     * @memberof PartStatistics
     */
    'SuccessRate': SuccessRate;
    /**
     * 
     * @type {ProgressRate}
     * @memberof PartStatistics
     */
    'ProgressRate': ProgressRate;
    /**
     * 
     * @type {PartStatisticsLatestTestScore}
     * @memberof PartStatistics
     */
    'LatestTestScore': PartStatisticsLatestTestScore | null;
    /**
     * 
     * @type {PartStatisticsExerciseEvolution}
     * @memberof PartStatistics
     */
    'ExerciseEvolution': PartStatisticsExerciseEvolution | null;
}
/**
 * 
 * @export
 * @interface PartStatisticsExerciseEvolution
 */
export interface PartStatisticsExerciseEvolution {
    /**
     * 
     * @type {Array<number>}
     * @memberof PartStatisticsExerciseEvolution
     */
    'ExerciseNumber': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartStatisticsExerciseEvolution
     */
    'ExerciseScore': Array<number>;
}
/**
 * 
 * @export
 * @interface PartStatisticsLatestTestScore
 */
export interface PartStatisticsLatestTestScore {
    /**
     * 
     * @type {number}
     * @memberof PartStatisticsLatestTestScore
     */
    'Score': number;
    /**
     * 
     * @type {number}
     * @memberof PartStatisticsLatestTestScore
     */
    'MaxScore': number;
}
/**
 * 
 * @export
 * @interface PartlyCorrectAnswersListOfLists
 */
export interface PartlyCorrectAnswersListOfLists {
    /**
     * 
     * @type {Array<CorrectAnswersListOfLists>}
     * @memberof PartlyCorrectAnswersListOfLists
     */
    'partlyCorrect'?: Array<CorrectAnswersListOfLists>;
    /**
     * 
     * @type {Array<CorrectAnswersListOfLists>}
     * @memberof PartlyCorrectAnswersListOfLists
     */
    'correct'?: Array<CorrectAnswersListOfLists>;
}
/**
 * 
 * @export
 * @interface PatchClassroomRequest
 */
export interface PatchClassroomRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchClassroomRequest
     */
    'Name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchClassroomRequest
     */
    'AccessToCorrectAnswers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchClassroomRequest
     */
    'ExerciseRepetition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchClassroomRequest
     */
    'AccessToSpeakingTranscripts'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchClassroomRequest
     */
    'AccessToPracticeTests'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchClassroomRequest
     */
    'AccessToFeedback'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchClassroomRequest
     */
    'PausePracticeTests'?: boolean;
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof PatchClassroomRequest
     */
    'AccessToMockExams'?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof PatchClassroomRequest
     */
    'AccessToMockExamResults'?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {{ [key: string]: BlockedExercisesValue; }}
     * @memberof PatchClassroomRequest
     */
    'BlockedExercises'?: { [key: string]: BlockedExercisesValue; };
    /**
     * 
     * @type {Array<Level>}
     * @memberof PatchClassroomRequest
     */
    'AccessToLevels'?: Array<Level>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchClassroomRequest
     */
    'AllowExtraTimeExam'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchDistributorRequest
 */
export interface PatchDistributorRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchDistributorRequest
     */
    'Name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchDistributorRequest
     */
    'Logo'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchDistributorRequest
     */
    'DistributorSubdomain'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchDistributorRequest
     */
    'AutoApprovePayments'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchDistributorUserRequest
 */
export interface PatchDistributorUserRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchDistributorUserRequest
     */
    'Name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchDistributorUserRequest
     */
    'Email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchDistributorUserRequest
     */
    'Active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchDistributorUserRequest
     */
    'Password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchDistributorUserRequest
     */
    'PermanentPassword'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchDistributorUserRequest
     */
    'ProfilePicture'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchDistributorUserRequest
     */
    'Region'?: string;
}
/**
 * 
 * @export
 * @interface PatchExerciseRequest
 */
export interface PatchExerciseRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchExerciseRequest
     */
    'QuestionIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchExerciseRequest
     */
    'Answer'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchExerciseRequest
     */
    'AIFeedback'?: string;
}
/**
 * 
 * @export
 * @interface PatchMockExamSubmissionRequest
 */
export interface PatchMockExamSubmissionRequest {
    /**
     * 
     * @type {MockExamSectionStatus}
     * @memberof PatchMockExamSubmissionRequest
     */
    'Status': MockExamSectionStatus;
    /**
     * 
     * @type {Section}
     * @memberof PatchMockExamSubmissionRequest
     */
    'Section': Section;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PatchMockExamSubmissionRequest
     */
    'TimeSpent'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof PatchMockExamSubmissionRequest
     */
    'Answers': { [key: string]: { [key: string]: string; }; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof PatchMockExamSubmissionRequest
     */
    'CompletedListeningParts'?: { [key: string]: boolean; };
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchMockExamSubmissionRequest
     */
    'Candidates'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PatchMockExamSubmissionRequest
     */
    'SelectedWriting'?: string | null;
}


/**
 * 
 * @export
 * @interface PatchOrderRequest
 */
export interface PatchOrderRequest {
    /**
     * 
     * @type {OrderStatus}
     * @memberof PatchOrderRequest
     */
    'Status': OrderStatus;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof PatchOrderRequest
     */
    'Licenses'?: { [key: string]: { [key: string]: number; }; };
}


/**
 * 
 * @export
 * @interface PatchPaymentRequest
 */
export interface PatchPaymentRequest {
    /**
     * 
     * @type {PaymentStatus}
     * @memberof PatchPaymentRequest
     */
    'PaymentStatus'?: PaymentStatus;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof PatchPaymentRequest
     */
    'Licenses'?: { [key: string]: { [key: string]: number; }; };
}


/**
 * 
 * @export
 * @interface PatchPracticeTestRequest
 */
export interface PatchPracticeTestRequest {
    /**
     * 
     * @type {PracticeTestStatus}
     * @memberof PatchPracticeTestRequest
     */
    'Status': PracticeTestStatus;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PatchPracticeTestRequest
     */
    'TimeSpent'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof PatchPracticeTestRequest
     */
    'Answers': { [key: string]: { [key: string]: string; }; };
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchPracticeTestRequest
     */
    'Candidates'?: Array<string>;
}


/**
 * 
 * @export
 * @interface PatchSchoolRequest
 */
export interface PatchSchoolRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchSchoolRequest
     */
    'Name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchSchoolRequest
     */
    'SchoolSubdomain'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchSchoolRequest
     */
    'SchoolLogo'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchSchoolRequest
     */
    'SchoolBackground'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchSchoolRequest
     */
    'BillingName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchSchoolRequest
     */
    'Country'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchSchoolRequest
     */
    'City'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchSchoolRequest
     */
    'Address'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchSchoolRequest
     */
    'ZipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchSchoolRequest
     */
    'TaxId'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof PatchSchoolRequest
     */
    'Discount'?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {boolean}
     * @memberof PatchSchoolRequest
     */
    'AutoApprovePayments'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchSubmissionRequest
 */
export interface PatchSubmissionRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchSubmissionRequest
     */
    'TestScore'?: number;
    /**
     * 
     * @type {MarkingScheme}
     * @memberof PatchSubmissionRequest
     */
    'MarkingScheme'?: MarkingScheme;
    /**
     * 
     * @type {string}
     * @memberof PatchSubmissionRequest
     */
    'Feedback'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchSubmissionRequest
     */
    'UserId'?: string;
}
/**
 * 
 * @export
 * @interface PatchUserRequest
 */
export interface PatchUserRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    'Name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    'Email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchUserRequest
     */
    'Active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    'Password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchUserRequest
     */
    'PermanentPassword'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchUserRequest
     */
    'AccessToCorrectAnswers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchUserRequest
     */
    'ExerciseRepetition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchUserRequest
     */
    'AccessToSpeakingTranscripts'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchUserRequest
     */
    'AccessToPracticeTests'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchUserRequest
     */
    'AccessToFeedback'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchUserRequest
     */
    'PausePracticeTests'?: boolean;
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof PatchUserRequest
     */
    'AccessToMockExams'?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof PatchUserRequest
     */
    'AccessToMockExamResults'?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof PatchUserRequest
     */
    'BlockedExercises'?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {Array<Level>}
     * @memberof PatchUserRequest
     */
    'AccessToLevels'?: Array<Level>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchUserRequest
     */
    'AllowExtraTimeExam'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    'ProfilePicture'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchUserRequest
     */
    'Classrooms'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'PaymentId': string;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof Payment
     */
    'PaymentMethod': PaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'PaymentCreated': string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'PaymentAmount': number;
    /**
     * 
     * @type {Currency}
     * @memberof Payment
     */
    'PaymentCurrency': Currency;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: LicenseInvoice; }; }}
     * @memberof Payment
     */
    'Licenses': { [key: string]: { [key: string]: LicenseInvoice; }; };
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'InvoiceNumber': string;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof Payment
     */
    'PaymentStatus': PaymentStatus;
}


/**
 * 
 * @export
 * @interface PaymentIntent
 */
export interface PaymentIntent {
    /**
     * 
     * @type {string}
     * @memberof PaymentIntent
     */
    'RedirectURL': string;
}
/**
 * 
 * @export
 * @interface PaymentLicensesListResponse
 */
export interface PaymentLicensesListResponse {
    /**
     * 
     * @type {Array<License>}
     * @memberof PaymentLicensesListResponse
     */
    'PaymentLicensesList': Array<License>;
    /**
     * Next Token for paginated results
     * @type {string}
     * @memberof PaymentLicensesListResponse
     */
    'NextToken': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentMethod = {
    Free: 'Free',
    Card: 'Card',
    BankTransfer: 'BankTransfer',
    StripeCard: 'StripeCard',
    Braintree: 'Braintree'
} as const;

export type PaymentMethod = typeof PaymentMethod[keyof typeof PaymentMethod];


/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentStatus = {
    Succeeded: 'Succeeded',
    Pending: 'Pending',
    Approved: 'Approved',
    Canceled: 'Canceled'
} as const;

export type PaymentStatus = typeof PaymentStatus[keyof typeof PaymentStatus];


/**
 * 
 * @export
 * @interface PaymentsListResponse
 */
export interface PaymentsListResponse {
    /**
     * 
     * @type {Array<Payment>}
     * @memberof PaymentsListResponse
     */
    'PaymentsList': Array<Payment>;
    /**
     * Next Token for paginated results
     * @type {string}
     * @memberof PaymentsListResponse
     */
    'NextToken': string | null;
}
/**
 * 
 * @export
 * @interface PracticeTest
 */
export interface PracticeTest {
    /**
     * 
     * @type {string}
     * @memberof PracticeTest
     */
    'PracticeTestId': string;
    /**
     * 
     * @type {string}
     * @memberof PracticeTest
     */
    'UserId'?: string;
    /**
     * 
     * @type {Level}
     * @memberof PracticeTest
     */
    'Level': Level;
    /**
     * 
     * @type {Section}
     * @memberof PracticeTest
     */
    'Section': Section;
    /**
     * 
     * @type {number}
     * @memberof PracticeTest
     */
    'Duration': number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PracticeTest
     */
    'TimeSpent': { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PracticeTest
     */
    'Exercises': { [key: string]: string; };
    /**
     * 
     * @type {PracticeTestStatus}
     * @memberof PracticeTest
     */
    'Status': PracticeTestStatus;
    /**
     * 
     * @type {string}
     * @memberof PracticeTest
     */
    'Started': string;
    /**
     * 
     * @type {string}
     * @memberof PracticeTest
     */
    'Finished'?: string | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof PracticeTest
     */
    'Answers'?: { [key: string]: { [key: string]: string; }; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PracticeTest
     */
    'Submissions'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: ExerciseTestScore; }}
     * @memberof PracticeTest
     */
    'TestScore'?: { [key: string]: ExerciseTestScore; };
    /**
     * 
     * @type {OverallTestScore}
     * @memberof PracticeTest
     */
    'OverallTestScore'?: OverallTestScore | null;
    /**
     * 
     * @type {{ [key: string]: Array<PracticeTestCorrectAnswersValueInner>; }}
     * @memberof PracticeTest
     */
    'CorrectAnswers'?: { [key: string]: Array<PracticeTestCorrectAnswersValueInner>; };
}


/**
 * @type PracticeTestCorrectAnswersValueInner
 * @export
 */
export type PracticeTestCorrectAnswersValueInner = Array<string> | CorrectAnswersListOfLists | PartlyCorrectAnswersListOfLists;

/**
 * 
 * @export
 * @enum {string}
 */

export const PracticeTestStatus = {
    Started: 'Started',
    Paused: 'Paused',
    ToBeCorrected: 'ToBeCorrected',
    Finished: 'Finished'
} as const;

export type PracticeTestStatus = typeof PracticeTestStatus[keyof typeof PracticeTestStatus];


/**
 * 
 * @export
 * @interface ProgressRate
 */
export interface ProgressRate {
    /**
     * 
     * @type {number}
     * @memberof ProgressRate
     */
    'CompletedExercises'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgressRate
     */
    'TotalExercises'?: number;
}
/**
 * 
 * @export
 * @interface ResetMockExamSubmissionRequest
 */
export interface ResetMockExamSubmissionRequest {
    /**
     * 
     * @type {Section}
     * @memberof ResetMockExamSubmissionRequest
     */
    'Section': Section;
}


/**
 * 
 * @export
 * @interface RevokeLicenseRequest
 */
export interface RevokeLicenseRequest {
    /**
     * 
     * @type {string}
     * @memberof RevokeLicenseRequest
     */
    'SchoolId': string;
    /**
     * 
     * @type {string}
     * @memberof RevokeLicenseRequest
     */
    'UserId': string;
    /**
     * 
     * @type {Role}
     * @memberof RevokeLicenseRequest
     */
    'Role': Role;
}


/**
 * User role
 * @export
 * @enum {string}
 */

export const Role = {
    SuperAdmin: 'SuperAdmin',
    Admin: 'Admin',
    Teacher: 'Teacher',
    Student: 'Student'
} as const;

export type Role = typeof Role[keyof typeof Role];


/**
 * 
 * @export
 * @interface School
 */
export interface School {
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'SchoolId': string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'SchoolSubdomain': string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'SchoolLogo': string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'SchoolBackground': string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'DistributorId': string | null;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'AgentId': string | null;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'BillingName': string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'Country': string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'City': string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'Address': string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'ZipCode': string;
    /**
     * 
     * @type {string}
     * @memberof School
     */
    'TaxId': string;
    /**
     * 
     * @type {Currency}
     * @memberof School
     */
    'Currency': Currency;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof School
     */
    'Discount': { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof School
     */
    'Config'?: { [key: string]: boolean; };
}


/**
 * 
 * @export
 * @interface SchoolsListResponse
 */
export interface SchoolsListResponse {
    /**
     * 
     * @type {Array<School>}
     * @memberof SchoolsListResponse
     */
    'SchoolsList': Array<School>;
    /**
     * Next Token for paginated results
     * @type {string}
     * @memberof SchoolsListResponse
     */
    'NextToken': string | null;
}
/**
 * Exercise section
 * @export
 * @enum {string}
 */

export const Section = {
    Raw: 'RAW',
    Rau: 'RAU',
    Wri: 'WRI',
    Lis: 'LIS',
    Spe: 'SPE',
    Rea: 'REA',
    Use: 'USE'
} as const;

export type Section = typeof Section[keyof typeof Section];


/**
 * 
 * @export
 * @interface SectionStatistics
 */
export interface SectionStatistics {
    /**
     * 
     * @type {GlobalStatistics}
     * @memberof SectionStatistics
     */
    'Section'?: GlobalStatistics;
    /**
     * 
     * @type {{ [key: string]: PartStatistics; }}
     * @memberof SectionStatistics
     */
    'Parts'?: { [key: string]: PartStatistics; };
}
/**
 * 
 * @export
 * @interface SignUpRequest
 */
export interface SignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'Email': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'Password': string;
}
/**
 * 
 * @export
 * @interface StartMockExamRequest
 */
export interface StartMockExamRequest {
    /**
     * 
     * @type {Level}
     * @memberof StartMockExamRequest
     */
    'Level': Level;
    /**
     * 
     * @type {number}
     * @memberof StartMockExamRequest
     */
    'Number': number;
}


/**
 * 
 * @export
 * @interface Subdomain
 */
export interface Subdomain {
    /**
     * 
     * @type {string}
     * @memberof Subdomain
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof Subdomain
     */
    'SchoolName': string;
    /**
     * 
     * @type {string}
     * @memberof Subdomain
     */
    'Logo': string;
    /**
     * 
     * @type {string}
     * @memberof Subdomain
     */
    'Background': string;
}
/**
 * 
 * @export
 * @interface Submission
 */
export interface Submission {
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    'SubmissionId': string;
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    'ExerciseId': string;
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    'UserId': string;
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    'CreatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Submission
     */
    'TimeSpent': number;
    /**
     * 
     * @type {object}
     * @memberof Submission
     */
    'Answers': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof Submission
     */
    'CorrectAnswers': Array<string>;
    /**
     * 
     * @type {ExerciseTestScore}
     * @memberof Submission
     */
    'TestScore': ExerciseTestScore;
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    'Feedback': string;
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    'PracticeTestId': string | null;
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    'MockSubmissionId': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Submission
     */
    'Candidates'?: Array<string>;
}
/**
 * 
 * @export
 * @interface SubmissionItem
 */
export interface SubmissionItem {
    /**
     * 
     * @type {string}
     * @memberof SubmissionItem
     */
    'SubmissionId': string;
    /**
     * 
     * @type {number}
     * @memberof SubmissionItem
     */
    'ExerciseNumber': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionItem
     */
    'CreatedAt': string;
    /**
     * 
     * @type {ExerciseTestScore}
     * @memberof SubmissionItem
     */
    'TestScore': ExerciseTestScore;
    /**
     * 
     * @type {Part}
     * @memberof SubmissionItem
     */
    'Part': Part;
    /**
     * 
     * @type {string}
     * @memberof SubmissionItem
     */
    'PracticeTestId': string | null;
}


/**
 * 
 * @export
 * @interface SubmissionItemsListResponse
 */
export interface SubmissionItemsListResponse {
    /**
     * 
     * @type {Array<SubmissionItem>}
     * @memberof SubmissionItemsListResponse
     */
    'SubmissionItemsList': Array<SubmissionItem>;
    /**
     * Next Token for paginated results
     * @type {string}
     * @memberof SubmissionItemsListResponse
     */
    'NextToken': string | null;
}
/**
 * 
 * @export
 * @interface SuccessRate
 */
export interface SuccessRate {
    /**
     * 
     * @type {number}
     * @memberof SuccessRate
     */
    'Average'?: number;
    /**
     * 
     * @type {number}
     * @memberof SuccessRate
     */
    'Latest'?: number;
}
/**
 * 
 * @export
 * @interface TotalClassroomMockExamScore
 */
export interface TotalClassroomMockExamScore {
    /**
     * 
     * @type {number}
     * @memberof TotalClassroomMockExamScore
     */
    'Score': number;
    /**
     * 
     * @type {number}
     * @memberof TotalClassroomMockExamScore
     */
    'MaxScore': number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'UserId': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'Email': string;
    /**
     * 
     * @type {Role}
     * @memberof User
     */
    'Role': Role;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'Created': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'Active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'LastAccess'?: string;
    /**
     * 
     * @type {Array<Level>}
     * @memberof User
     */
    'AccessToLevels'?: Array<Level>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'ProfilePicture'?: string;
    /**
     * 
     * @type {Array<LicenseHistoryItem>}
     * @memberof User
     */
    'LicenseHistory'?: Array<LicenseHistoryItem>;
    /**
     * 
     * @type {{ [key: string]: Array<Level>; }}
     * @memberof User
     */
    'StartedLevels'?: { [key: string]: Array<Level>; };
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof User
     */
    'AccessToMockExams'?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof User
     */
    'AccessToMockExamResults'?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'AccessToCorrectAnswers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'ExerciseRepetition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'AccessToSpeakingTranscripts'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'AccessToPracticeTests'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'AccessToFeedback'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'PausePracticeTests'?: boolean;
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof User
     */
    'BlockedExercises'?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'AllowExtraTimeExam'?: boolean;
}


/**
 * 
 * @export
 * @interface UserStatistics
 */
export interface UserStatistics {
    /**
     * 
     * @type {GlobalStatistics}
     * @memberof UserStatistics
     */
    'Level': GlobalStatistics;
    /**
     * 
     * @type {{ [key: string]: SectionStatistics; }}
     * @memberof UserStatistics
     */
    'Sections': { [key: string]: SectionStatistics; };
}
/**
 * 
 * @export
 * @interface UsersListResponse
 */
export interface UsersListResponse {
    /**
     * 
     * @type {Array<User>}
     * @memberof UsersListResponse
     */
    'UsersList': Array<User>;
    /**
     * Next Token for paginated results
     * @type {string}
     * @memberof UsersListResponse
     */
    'NextToken': string | null;
}

/**
 * ClassroomsApi - axios parameter creator
 * @export
 */
export const ClassroomsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Adds a new member to a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {AddClassroomMemberRequest} addClassroomMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClassroomMember: async (classroomId: string, addClassroomMemberRequest: AddClassroomMemberRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('addClassroomMember', 'classroomId', classroomId)
            // verify required parameter 'addClassroomMemberRequest' is not null or undefined
            assertParamExists('addClassroomMember', 'addClassroomMemberRequest', addClassroomMemberRequest)
            const localVarPath = `/classrooms/{ClassroomId}/members`
                .replace(`{${"ClassroomId"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addClassroomMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Creates a new classroom inside of a school in English Exams Lab
         * @param {CreateClassroomRequest} createClassroomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClassroom: async (createClassroomRequest: CreateClassroomRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClassroomRequest' is not null or undefined
            assertParamExists('createClassroom', 'createClassroomRequest', createClassroomRequest)
            const localVarPath = `/classrooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClassroomRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Delete a classroom from English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClassroom: async (classroomId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('deleteClassroom', 'classroomId', classroomId)
            const localVarPath = `/classrooms/{ClassroomId}`
                .replace(`{${"ClassroomId"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Deletes a member from a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {string} userId User identifier
         * @param {DeleteClassroomMemberRoleEnum} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClassroomMember: async (classroomId: string, userId: string, role: DeleteClassroomMemberRoleEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('deleteClassroomMember', 'classroomId', classroomId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteClassroomMember', 'userId', userId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('deleteClassroomMember', 'role', role)
            const localVarPath = `/classrooms/{ClassroomId}/members/{UserId}`
                .replace(`{${"ClassroomId"}}`, encodeURIComponent(String(classroomId)))
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve classroom by its ID
         * @param {string} classroomId Classroom identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroom: async (classroomId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('getClassroom', 'classroomId', classroomId)
            const localVarPath = `/classrooms/{ClassroomId}`
                .replace(`{${"ClassroomId"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary List all members of a classroom in a school of English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {boolean} [hasLicense] User has a valid license
         * @param {Level} [mockExamLevel] Mock exam level to filter classroom members who have not started the provided mock exam (speaking)
         * @param {number} [mockExamNumber] Mock exam number to filter classroom members who have not started the provided mock exam (speaking)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassroomMembers: async (classroomId: string, hasLicense?: boolean, mockExamLevel?: Level, mockExamNumber?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('listClassroomMembers', 'classroomId', classroomId)
            const localVarPath = `/classrooms/{ClassroomId}/members`
                .replace(`{${"ClassroomId"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (hasLicense !== undefined) {
                localVarQueryParameter['hasLicense'] = hasLicense;
            }

            if (mockExamLevel !== undefined) {
                localVarQueryParameter['mockExamLevel'] = mockExamLevel;
            }

            if (mockExamNumber !== undefined) {
                localVarQueryParameter['mockExamNumber'] = mockExamNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary List all mock exams of a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassroomMockExams: async (classroomId: string, maxResults?: number, nextToken?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('listClassroomMockExams', 'classroomId', classroomId)
            const localVarPath = `/classrooms/{ClassroomId}/mock-exams`
                .replace(`{${"ClassroomId"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary List all practice tests of a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassroomPracticeTests: async (classroomId: string, maxResults?: number, nextToken?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('listClassroomPracticeTests', 'classroomId', classroomId)
            const localVarPath = `/classrooms/{ClassroomId}/practice-tests`
                .replace(`{${"ClassroomId"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary List all submissions of a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassroomSubmissions: async (classroomId: string, maxResults?: number, nextToken?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('listClassroomSubmissions', 'classroomId', classroomId)
            const localVarPath = `/classrooms/{ClassroomId}/submissions`
                .replace(`{${"ClassroomId"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary List all classrooms in a school of English Exams Lab
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassrooms: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/classrooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing classroom
         * @param {string} classroomId Classroom identifier
         * @param {PatchClassroomRequest} patchClassroomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchClassroom: async (classroomId: string, patchClassroomRequest: PatchClassroomRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classroomId' is not null or undefined
            assertParamExists('patchClassroom', 'classroomId', classroomId)
            // verify required parameter 'patchClassroomRequest' is not null or undefined
            assertParamExists('patchClassroom', 'patchClassroomRequest', patchClassroomRequest)
            const localVarPath = `/classrooms/{ClassroomId}`
                .replace(`{${"ClassroomId"}}`, encodeURIComponent(String(classroomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchClassroomRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClassroomsApi - functional programming interface
 * @export
 */
export const ClassroomsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClassroomsApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Adds a new member to a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {AddClassroomMemberRequest} addClassroomMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addClassroomMember(classroomId: string, addClassroomMemberRequest: AddClassroomMemberRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addClassroomMember(classroomId, addClassroomMemberRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassroomsApi.addClassroomMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Creates a new classroom inside of a school in English Exams Lab
         * @param {CreateClassroomRequest} createClassroomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClassroom(createClassroomRequest: CreateClassroomRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Classroom>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClassroom(createClassroomRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassroomsApi.createClassroom']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Delete a classroom from English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClassroom(classroomId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClassroom(classroomId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassroomsApi.deleteClassroom']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Deletes a member from a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {string} userId User identifier
         * @param {DeleteClassroomMemberRoleEnum} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClassroomMember(classroomId: string, userId: string, role: DeleteClassroomMemberRoleEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClassroomMember(classroomId, userId, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassroomsApi.deleteClassroomMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve classroom by its ID
         * @param {string} classroomId Classroom identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassroom(classroomId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Classroom>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassroom(classroomId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassroomsApi.getClassroom']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary List all members of a classroom in a school of English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {boolean} [hasLicense] User has a valid license
         * @param {Level} [mockExamLevel] Mock exam level to filter classroom members who have not started the provided mock exam (speaking)
         * @param {number} [mockExamNumber] Mock exam number to filter classroom members who have not started the provided mock exam (speaking)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClassroomMembers(classroomId: string, hasLicense?: boolean, mockExamLevel?: Level, mockExamNumber?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassroomMember>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClassroomMembers(classroomId, hasLicense, mockExamLevel, mockExamNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassroomsApi.listClassroomMembers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary List all mock exams of a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClassroomMockExams(classroomId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassroomMockExamsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClassroomMockExams(classroomId, maxResults, nextToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassroomsApi.listClassroomMockExams']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary List all practice tests of a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClassroomPracticeTests(classroomId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassroomPracticeTestsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClassroomPracticeTests(classroomId, maxResults, nextToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassroomsApi.listClassroomPracticeTests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary List all submissions of a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClassroomSubmissions(classroomId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassroomSubmissionsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClassroomSubmissions(classroomId, maxResults, nextToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassroomsApi.listClassroomSubmissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary List all classrooms in a school of English Exams Lab
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClassrooms(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Classroom>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClassrooms(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassroomsApi.listClassrooms']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing classroom
         * @param {string} classroomId Classroom identifier
         * @param {PatchClassroomRequest} patchClassroomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchClassroom(classroomId: string, patchClassroomRequest: PatchClassroomRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Classroom>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchClassroom(classroomId, patchClassroomRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClassroomsApi.patchClassroom']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ClassroomsApi - factory interface
 * @export
 */
export const ClassroomsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClassroomsApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Adds a new member to a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {AddClassroomMemberRequest} addClassroomMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClassroomMember(classroomId: string, addClassroomMemberRequest: AddClassroomMemberRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.addClassroomMember(classroomId, addClassroomMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Creates a new classroom inside of a school in English Exams Lab
         * @param {CreateClassroomRequest} createClassroomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClassroom(createClassroomRequest: CreateClassroomRequest, options?: RawAxiosRequestConfig): AxiosPromise<Classroom> {
            return localVarFp.createClassroom(createClassroomRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Delete a classroom from English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClassroom(classroomId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteClassroom(classroomId, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Deletes a member from a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {string} userId User identifier
         * @param {DeleteClassroomMemberRoleEnum} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClassroomMember(classroomId: string, userId: string, role: DeleteClassroomMemberRoleEnum, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteClassroomMember(classroomId, userId, role, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve classroom by its ID
         * @param {string} classroomId Classroom identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassroom(classroomId: string, options?: RawAxiosRequestConfig): AxiosPromise<Classroom> {
            return localVarFp.getClassroom(classroomId, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary List all members of a classroom in a school of English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {boolean} [hasLicense] User has a valid license
         * @param {Level} [mockExamLevel] Mock exam level to filter classroom members who have not started the provided mock exam (speaking)
         * @param {number} [mockExamNumber] Mock exam number to filter classroom members who have not started the provided mock exam (speaking)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassroomMembers(classroomId: string, hasLicense?: boolean, mockExamLevel?: Level, mockExamNumber?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ClassroomMember>> {
            return localVarFp.listClassroomMembers(classroomId, hasLicense, mockExamLevel, mockExamNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary List all mock exams of a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassroomMockExams(classroomId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ClassroomMockExamsListResponse> {
            return localVarFp.listClassroomMockExams(classroomId, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary List all practice tests of a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassroomPracticeTests(classroomId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ClassroomPracticeTestsListResponse> {
            return localVarFp.listClassroomPracticeTests(classroomId, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary List all submissions of a classroom inside of a school in English Exams Lab
         * @param {string} classroomId Classroom identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassroomSubmissions(classroomId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ClassroomSubmissionsListResponse> {
            return localVarFp.listClassroomSubmissions(classroomId, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary List all classrooms in a school of English Exams Lab
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassrooms(options?: RawAxiosRequestConfig): AxiosPromise<Array<Classroom>> {
            return localVarFp.listClassrooms(options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing classroom
         * @param {string} classroomId Classroom identifier
         * @param {PatchClassroomRequest} patchClassroomRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchClassroom(classroomId: string, patchClassroomRequest: PatchClassroomRequest, options?: RawAxiosRequestConfig): AxiosPromise<Classroom> {
            return localVarFp.patchClassroom(classroomId, patchClassroomRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClassroomsApi - object-oriented interface
 * @export
 * @class ClassroomsApi
 * @extends {BaseAPI}
 */
export class ClassroomsApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Adds a new member to a classroom inside of a school in English Exams Lab
     * @param {string} classroomId Classroom identifier
     * @param {AddClassroomMemberRequest} addClassroomMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public addClassroomMember(classroomId: string, addClassroomMemberRequest: AddClassroomMemberRequest, options?: RawAxiosRequestConfig) {
        return ClassroomsApiFp(this.configuration).addClassroomMember(classroomId, addClassroomMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Creates a new classroom inside of a school in English Exams Lab
     * @param {CreateClassroomRequest} createClassroomRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public createClassroom(createClassroomRequest: CreateClassroomRequest, options?: RawAxiosRequestConfig) {
        return ClassroomsApiFp(this.configuration).createClassroom(createClassroomRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Delete a classroom from English Exams Lab
     * @param {string} classroomId Classroom identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public deleteClassroom(classroomId: string, options?: RawAxiosRequestConfig) {
        return ClassroomsApiFp(this.configuration).deleteClassroom(classroomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Deletes a member from a classroom inside of a school in English Exams Lab
     * @param {string} classroomId Classroom identifier
     * @param {string} userId User identifier
     * @param {DeleteClassroomMemberRoleEnum} role User role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public deleteClassroomMember(classroomId: string, userId: string, role: DeleteClassroomMemberRoleEnum, options?: RawAxiosRequestConfig) {
        return ClassroomsApiFp(this.configuration).deleteClassroomMember(classroomId, userId, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve classroom by its ID
     * @param {string} classroomId Classroom identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public getClassroom(classroomId: string, options?: RawAxiosRequestConfig) {
        return ClassroomsApiFp(this.configuration).getClassroom(classroomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary List all members of a classroom in a school of English Exams Lab
     * @param {string} classroomId Classroom identifier
     * @param {boolean} [hasLicense] User has a valid license
     * @param {Level} [mockExamLevel] Mock exam level to filter classroom members who have not started the provided mock exam (speaking)
     * @param {number} [mockExamNumber] Mock exam number to filter classroom members who have not started the provided mock exam (speaking)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public listClassroomMembers(classroomId: string, hasLicense?: boolean, mockExamLevel?: Level, mockExamNumber?: number, options?: RawAxiosRequestConfig) {
        return ClassroomsApiFp(this.configuration).listClassroomMembers(classroomId, hasLicense, mockExamLevel, mockExamNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary List all mock exams of a classroom inside of a school in English Exams Lab
     * @param {string} classroomId Classroom identifier
     * @param {number} [maxResults] Maximum number of results to return
     * @param {string | null} [nextToken] Next token for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public listClassroomMockExams(classroomId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig) {
        return ClassroomsApiFp(this.configuration).listClassroomMockExams(classroomId, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary List all practice tests of a classroom inside of a school in English Exams Lab
     * @param {string} classroomId Classroom identifier
     * @param {number} [maxResults] Maximum number of results to return
     * @param {string | null} [nextToken] Next token for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public listClassroomPracticeTests(classroomId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig) {
        return ClassroomsApiFp(this.configuration).listClassroomPracticeTests(classroomId, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary List all submissions of a classroom inside of a school in English Exams Lab
     * @param {string} classroomId Classroom identifier
     * @param {number} [maxResults] Maximum number of results to return
     * @param {string | null} [nextToken] Next token for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public listClassroomSubmissions(classroomId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig) {
        return ClassroomsApiFp(this.configuration).listClassroomSubmissions(classroomId, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary List all classrooms in a school of English Exams Lab
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public listClassrooms(options?: RawAxiosRequestConfig) {
        return ClassroomsApiFp(this.configuration).listClassrooms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Patch an existing classroom
     * @param {string} classroomId Classroom identifier
     * @param {PatchClassroomRequest} patchClassroomRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassroomsApi
     */
    public patchClassroom(classroomId: string, patchClassroomRequest: PatchClassroomRequest, options?: RawAxiosRequestConfig) {
        return ClassroomsApiFp(this.configuration).patchClassroom(classroomId, patchClassroomRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const DeleteClassroomMemberRoleEnum = {
    Teacher: 'Teacher',
    Student: 'Student'
} as const;
export type DeleteClassroomMemberRoleEnum = typeof DeleteClassroomMemberRoleEnum[keyof typeof DeleteClassroomMemberRoleEnum];


/**
 * CorrectionsApi - axios parameter creator
 * @export
 */
export const CorrectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve a list of all pending corrections
         * @param {boolean} [hasCorrections] User has pending corrections
         * @param {boolean} [counter] Counter of pending corrections per classroom
         * @param {CorrectionType} [correctionType] Type of correction
         * @param {string} [classroomId] Classroom id
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCorrections: async (hasCorrections?: boolean, counter?: boolean, correctionType?: CorrectionType, classroomId?: string, maxResults?: number, nextToken?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/corrections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (hasCorrections !== undefined) {
                localVarQueryParameter['hasCorrections'] = hasCorrections;
            }

            if (counter !== undefined) {
                localVarQueryParameter['counter'] = counter;
            }

            if (correctionType !== undefined) {
                localVarQueryParameter['correctionType'] = correctionType;
            }

            if (classroomId !== undefined) {
                localVarQueryParameter['classroomId'] = classroomId;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CorrectionsApi - functional programming interface
 * @export
 */
export const CorrectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CorrectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve a list of all pending corrections
         * @param {boolean} [hasCorrections] User has pending corrections
         * @param {boolean} [counter] Counter of pending corrections per classroom
         * @param {CorrectionType} [correctionType] Type of correction
         * @param {string} [classroomId] Classroom id
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCorrections(hasCorrections?: boolean, counter?: boolean, correctionType?: CorrectionType, classroomId?: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCorrections200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCorrections(hasCorrections, counter, correctionType, classroomId, maxResults, nextToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CorrectionsApi.listCorrections']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CorrectionsApi - factory interface
 * @export
 */
export const CorrectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CorrectionsApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve a list of all pending corrections
         * @param {boolean} [hasCorrections] User has pending corrections
         * @param {boolean} [counter] Counter of pending corrections per classroom
         * @param {CorrectionType} [correctionType] Type of correction
         * @param {string} [classroomId] Classroom id
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCorrections(hasCorrections?: boolean, counter?: boolean, correctionType?: CorrectionType, classroomId?: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ListCorrections200Response> {
            return localVarFp.listCorrections(hasCorrections, counter, correctionType, classroomId, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CorrectionsApi - object-oriented interface
 * @export
 * @class CorrectionsApi
 * @extends {BaseAPI}
 */
export class CorrectionsApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Retrieve a list of all pending corrections
     * @param {boolean} [hasCorrections] User has pending corrections
     * @param {boolean} [counter] Counter of pending corrections per classroom
     * @param {CorrectionType} [correctionType] Type of correction
     * @param {string} [classroomId] Classroom id
     * @param {number} [maxResults] Maximum number of results to return
     * @param {string | null} [nextToken] Next token for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectionsApi
     */
    public listCorrections(hasCorrections?: boolean, counter?: boolean, correctionType?: CorrectionType, classroomId?: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig) {
        return CorrectionsApiFp(this.configuration).listCorrections(hasCorrections, counter, correctionType, classroomId, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DistributorEmployeesApi - axios parameter creator
 * @export
 */
export const DistributorEmployeesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new English Exams Lab distributor user (distributor admin or agent)
         * @param {string} distributorId Distributor identifier
         * @param {CreateDistributorUserRequest} createDistributorUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDistributorUser: async (distributorId: string, createDistributorUserRequest: CreateDistributorUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'distributorId' is not null or undefined
            assertParamExists('createDistributorUser', 'distributorId', distributorId)
            // verify required parameter 'createDistributorUserRequest' is not null or undefined
            assertParamExists('createDistributorUser', 'createDistributorUserRequest', createDistributorUserRequest)
            const localVarPath = `/distributors/{DistributorId}/users`
                .replace(`{${"DistributorId"}}`, encodeURIComponent(String(distributorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDistributorUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Delete a distributor user from English Exams Lab
         * @param {string} distributorId Distributor identifier
         * @param {string} userId User identifier
         * @param {DistributorUserRole} role Distributor user role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDistributorUser: async (distributorId: string, userId: string, role: DistributorUserRole, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'distributorId' is not null or undefined
            assertParamExists('deleteDistributorUser', 'distributorId', distributorId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteDistributorUser', 'userId', userId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('deleteDistributorUser', 'role', role)
            const localVarPath = `/distributors/{DistributorId}/users/{UserId}`
                .replace(`{${"DistributorId"}}`, encodeURIComponent(String(distributorId)))
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve distributor user by its ID
         * @param {string} distributorId Distributor identifier
         * @param {string} userId User identifier
         * @param {DistributorUserRole} role Distributor user role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributorUser: async (distributorId: string, userId: string, role: DistributorUserRole, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'distributorId' is not null or undefined
            assertParamExists('getDistributorUser', 'distributorId', distributorId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getDistributorUser', 'userId', userId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('getDistributorUser', 'role', role)
            const localVarPath = `/distributors/{DistributorId}/users/{UserId}`
                .replace(`{${"DistributorId"}}`, encodeURIComponent(String(distributorId)))
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary List all distributor employees of an English Exams Lab distributor
         * @param {string} distributorId Distributor identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDistributorUsers: async (distributorId: string, maxResults?: number, nextToken?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'distributorId' is not null or undefined
            assertParamExists('listDistributorUsers', 'distributorId', distributorId)
            const localVarPath = `/distributors/{DistributorId}/users`
                .replace(`{${"DistributorId"}}`, encodeURIComponent(String(distributorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing distributor user
         * @param {string} distributorId Distributor identifier
         * @param {string} userId User identifier
         * @param {DistributorUserRole} role Distributor user role
         * @param {PatchDistributorUserRequest} patchDistributorUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDistributorUser: async (distributorId: string, userId: string, role: DistributorUserRole, patchDistributorUserRequest: PatchDistributorUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'distributorId' is not null or undefined
            assertParamExists('patchDistributorUser', 'distributorId', distributorId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchDistributorUser', 'userId', userId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('patchDistributorUser', 'role', role)
            // verify required parameter 'patchDistributorUserRequest' is not null or undefined
            assertParamExists('patchDistributorUser', 'patchDistributorUserRequest', patchDistributorUserRequest)
            const localVarPath = `/distributors/{DistributorId}/users/{UserId}`
                .replace(`{${"DistributorId"}}`, encodeURIComponent(String(distributorId)))
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchDistributorUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DistributorEmployeesApi - functional programming interface
 * @export
 */
export const DistributorEmployeesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DistributorEmployeesApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new English Exams Lab distributor user (distributor admin or agent)
         * @param {string} distributorId Distributor identifier
         * @param {CreateDistributorUserRequest} createDistributorUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDistributorUser(distributorId: string, createDistributorUserRequest: CreateDistributorUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributorUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDistributorUser(distributorId, createDistributorUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DistributorEmployeesApi.createDistributorUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Delete a distributor user from English Exams Lab
         * @param {string} distributorId Distributor identifier
         * @param {string} userId User identifier
         * @param {DistributorUserRole} role Distributor user role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDistributorUser(distributorId: string, userId: string, role: DistributorUserRole, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDistributorUser(distributorId, userId, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DistributorEmployeesApi.deleteDistributorUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve distributor user by its ID
         * @param {string} distributorId Distributor identifier
         * @param {string} userId User identifier
         * @param {DistributorUserRole} role Distributor user role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDistributorUser(distributorId: string, userId: string, role: DistributorUserRole, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributorUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDistributorUser(distributorId, userId, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DistributorEmployeesApi.getDistributorUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary List all distributor employees of an English Exams Lab distributor
         * @param {string} distributorId Distributor identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDistributorUsers(distributorId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributorUsersListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDistributorUsers(distributorId, maxResults, nextToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DistributorEmployeesApi.listDistributorUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing distributor user
         * @param {string} distributorId Distributor identifier
         * @param {string} userId User identifier
         * @param {DistributorUserRole} role Distributor user role
         * @param {PatchDistributorUserRequest} patchDistributorUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDistributorUser(distributorId: string, userId: string, role: DistributorUserRole, patchDistributorUserRequest: PatchDistributorUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributorUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchDistributorUser(distributorId, userId, role, patchDistributorUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DistributorEmployeesApi.patchDistributorUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DistributorEmployeesApi - factory interface
 * @export
 */
export const DistributorEmployeesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DistributorEmployeesApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new English Exams Lab distributor user (distributor admin or agent)
         * @param {string} distributorId Distributor identifier
         * @param {CreateDistributorUserRequest} createDistributorUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDistributorUser(distributorId: string, createDistributorUserRequest: CreateDistributorUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<DistributorUser> {
            return localVarFp.createDistributorUser(distributorId, createDistributorUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Delete a distributor user from English Exams Lab
         * @param {string} distributorId Distributor identifier
         * @param {string} userId User identifier
         * @param {DistributorUserRole} role Distributor user role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDistributorUser(distributorId: string, userId: string, role: DistributorUserRole, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteDistributorUser(distributorId, userId, role, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve distributor user by its ID
         * @param {string} distributorId Distributor identifier
         * @param {string} userId User identifier
         * @param {DistributorUserRole} role Distributor user role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributorUser(distributorId: string, userId: string, role: DistributorUserRole, options?: RawAxiosRequestConfig): AxiosPromise<DistributorUser> {
            return localVarFp.getDistributorUser(distributorId, userId, role, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary List all distributor employees of an English Exams Lab distributor
         * @param {string} distributorId Distributor identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDistributorUsers(distributorId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<DistributorUsersListResponse> {
            return localVarFp.listDistributorUsers(distributorId, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing distributor user
         * @param {string} distributorId Distributor identifier
         * @param {string} userId User identifier
         * @param {DistributorUserRole} role Distributor user role
         * @param {PatchDistributorUserRequest} patchDistributorUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDistributorUser(distributorId: string, userId: string, role: DistributorUserRole, patchDistributorUserRequest: PatchDistributorUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<DistributorUser> {
            return localVarFp.patchDistributorUser(distributorId, userId, role, patchDistributorUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DistributorEmployeesApi - object-oriented interface
 * @export
 * @class DistributorEmployeesApi
 * @extends {BaseAPI}
 */
export class DistributorEmployeesApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Creates a new English Exams Lab distributor user (distributor admin or agent)
     * @param {string} distributorId Distributor identifier
     * @param {CreateDistributorUserRequest} createDistributorUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorEmployeesApi
     */
    public createDistributorUser(distributorId: string, createDistributorUserRequest: CreateDistributorUserRequest, options?: RawAxiosRequestConfig) {
        return DistributorEmployeesApiFp(this.configuration).createDistributorUser(distributorId, createDistributorUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Delete a distributor user from English Exams Lab
     * @param {string} distributorId Distributor identifier
     * @param {string} userId User identifier
     * @param {DistributorUserRole} role Distributor user role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorEmployeesApi
     */
    public deleteDistributorUser(distributorId: string, userId: string, role: DistributorUserRole, options?: RawAxiosRequestConfig) {
        return DistributorEmployeesApiFp(this.configuration).deleteDistributorUser(distributorId, userId, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve distributor user by its ID
     * @param {string} distributorId Distributor identifier
     * @param {string} userId User identifier
     * @param {DistributorUserRole} role Distributor user role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorEmployeesApi
     */
    public getDistributorUser(distributorId: string, userId: string, role: DistributorUserRole, options?: RawAxiosRequestConfig) {
        return DistributorEmployeesApiFp(this.configuration).getDistributorUser(distributorId, userId, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary List all distributor employees of an English Exams Lab distributor
     * @param {string} distributorId Distributor identifier
     * @param {number} [maxResults] Maximum number of results to return
     * @param {string | null} [nextToken] Next token for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorEmployeesApi
     */
    public listDistributorUsers(distributorId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig) {
        return DistributorEmployeesApiFp(this.configuration).listDistributorUsers(distributorId, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Patch an existing distributor user
     * @param {string} distributorId Distributor identifier
     * @param {string} userId User identifier
     * @param {DistributorUserRole} role Distributor user role
     * @param {PatchDistributorUserRequest} patchDistributorUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorEmployeesApi
     */
    public patchDistributorUser(distributorId: string, userId: string, role: DistributorUserRole, patchDistributorUserRequest: PatchDistributorUserRequest, options?: RawAxiosRequestConfig) {
        return DistributorEmployeesApiFp(this.configuration).patchDistributorUser(distributorId, userId, role, patchDistributorUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DistributorsApi - axios parameter creator
 * @export
 */
export const DistributorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve distributor by its ID
         * @param {string} distributorId Distributor identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributor: async (distributorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'distributorId' is not null or undefined
            assertParamExists('getDistributor', 'distributorId', distributorId)
            const localVarPath = `/distributors/{DistributorId}`
                .replace(`{${"DistributorId"}}`, encodeURIComponent(String(distributorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve all distributor clients by the distributor id
         * @param {string} distributorId Distributor identifier
         * @param {string} [agent] agent identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDistributorClients: async (distributorId: string, agent?: string, maxResults?: number, nextToken?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'distributorId' is not null or undefined
            assertParamExists('listDistributorClients', 'distributorId', distributorId)
            const localVarPath = `/distributors/{DistributorId}/clients`
                .replace(`{${"DistributorId"}}`, encodeURIComponent(String(distributorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (agent !== undefined) {
                localVarQueryParameter['agent'] = agent;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing distributor
         * @param {string} distributorId Distributor identifier
         * @param {PatchDistributorRequest} patchDistributorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDistributor: async (distributorId: string, patchDistributorRequest: PatchDistributorRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'distributorId' is not null or undefined
            assertParamExists('patchDistributor', 'distributorId', distributorId)
            // verify required parameter 'patchDistributorRequest' is not null or undefined
            assertParamExists('patchDistributor', 'patchDistributorRequest', patchDistributorRequest)
            const localVarPath = `/distributors/{DistributorId}`
                .replace(`{${"DistributorId"}}`, encodeURIComponent(String(distributorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchDistributorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DistributorsApi - functional programming interface
 * @export
 */
export const DistributorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DistributorsApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve distributor by its ID
         * @param {string} distributorId Distributor identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDistributor(distributorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Distributor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDistributor(distributorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DistributorsApi.getDistributor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve all distributor clients by the distributor id
         * @param {string} distributorId Distributor identifier
         * @param {string} [agent] agent identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDistributorClients(distributorId: string, agent?: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributorClientsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDistributorClients(distributorId, agent, maxResults, nextToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DistributorsApi.listDistributorClients']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing distributor
         * @param {string} distributorId Distributor identifier
         * @param {PatchDistributorRequest} patchDistributorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDistributor(distributorId: string, patchDistributorRequest: PatchDistributorRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Distributor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchDistributor(distributorId, patchDistributorRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DistributorsApi.patchDistributor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DistributorsApi - factory interface
 * @export
 */
export const DistributorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DistributorsApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve distributor by its ID
         * @param {string} distributorId Distributor identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributor(distributorId: string, options?: RawAxiosRequestConfig): AxiosPromise<Distributor> {
            return localVarFp.getDistributor(distributorId, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve all distributor clients by the distributor id
         * @param {string} distributorId Distributor identifier
         * @param {string} [agent] agent identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDistributorClients(distributorId: string, agent?: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<DistributorClientsListResponse> {
            return localVarFp.listDistributorClients(distributorId, agent, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing distributor
         * @param {string} distributorId Distributor identifier
         * @param {PatchDistributorRequest} patchDistributorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDistributor(distributorId: string, patchDistributorRequest: PatchDistributorRequest, options?: RawAxiosRequestConfig): AxiosPromise<Distributor> {
            return localVarFp.patchDistributor(distributorId, patchDistributorRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DistributorsApi - object-oriented interface
 * @export
 * @class DistributorsApi
 * @extends {BaseAPI}
 */
export class DistributorsApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Retrieve distributor by its ID
     * @param {string} distributorId Distributor identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorsApi
     */
    public getDistributor(distributorId: string, options?: RawAxiosRequestConfig) {
        return DistributorsApiFp(this.configuration).getDistributor(distributorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve all distributor clients by the distributor id
     * @param {string} distributorId Distributor identifier
     * @param {string} [agent] agent identifier
     * @param {number} [maxResults] Maximum number of results to return
     * @param {string | null} [nextToken] Next token for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorsApi
     */
    public listDistributorClients(distributorId: string, agent?: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig) {
        return DistributorsApiFp(this.configuration).listDistributorClients(distributorId, agent, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Patch an existing distributor
     * @param {string} distributorId Distributor identifier
     * @param {PatchDistributorRequest} patchDistributorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorsApi
     */
    public patchDistributor(distributorId: string, patchDistributorRequest: PatchDistributorRequest, options?: RawAxiosRequestConfig) {
        return DistributorsApiFp(this.configuration).patchDistributor(distributorId, patchDistributorRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExercisesApi - axios parameter creator
 * @export
 */
export const ExercisesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve exercise by its ID
         * @param {GetExerciseByIdExerciseIdLevelParameter} exerciseIdLevel Exercise identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExerciseById: async (exerciseIdLevel: GetExerciseByIdExerciseIdLevelParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exerciseIdLevel' is not null or undefined
            assertParamExists('getExerciseById', 'exerciseIdLevel', exerciseIdLevel)
            const localVarPath = `/exercises/{ExerciseId-Level}`
                .replace(`{${"ExerciseId-Level"}}`, encodeURIComponent(String(exerciseIdLevel)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve exercise by its level/section/part/number
         * @param {GetExerciseByIdExerciseIdLevelParameter} exerciseIdLevel Exercise identifier
         * @param {Section} section Exercise section code
         * @param {Part} part Part inside of the section
         * @param {number} number Exercise number inside of the part
         * @param {Role} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExerciseByPosition: async (exerciseIdLevel: GetExerciseByIdExerciseIdLevelParameter, section: Section, part: Part, number: number, role: Role, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exerciseIdLevel' is not null or undefined
            assertParamExists('getExerciseByPosition', 'exerciseIdLevel', exerciseIdLevel)
            // verify required parameter 'section' is not null or undefined
            assertParamExists('getExerciseByPosition', 'section', section)
            // verify required parameter 'part' is not null or undefined
            assertParamExists('getExerciseByPosition', 'part', part)
            // verify required parameter 'number' is not null or undefined
            assertParamExists('getExerciseByPosition', 'number', number)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('getExerciseByPosition', 'role', role)
            const localVarPath = `/exercises/{ExerciseId-Level}/{Section}/{Part}/{Number}`
                .replace(`{${"ExerciseId-Level"}}`, encodeURIComponent(String(exerciseIdLevel)))
                .replace(`{${"Section"}}`, encodeURIComponent(String(section)))
                .replace(`{${"Part"}}`, encodeURIComponent(String(part)))
                .replace(`{${"Number"}}`, encodeURIComponent(String(number)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing exercise
         * @param {GetExerciseByIdExerciseIdLevelParameter} exerciseIdLevel Exercise identifier
         * @param {PatchExerciseRequest} patchExerciseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchExercise: async (exerciseIdLevel: GetExerciseByIdExerciseIdLevelParameter, patchExerciseRequest: PatchExerciseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exerciseIdLevel' is not null or undefined
            assertParamExists('patchExercise', 'exerciseIdLevel', exerciseIdLevel)
            // verify required parameter 'patchExerciseRequest' is not null or undefined
            assertParamExists('patchExercise', 'patchExerciseRequest', patchExerciseRequest)
            const localVarPath = `/exercises/{ExerciseId-Level}`
                .replace(`{${"ExerciseId-Level"}}`, encodeURIComponent(String(exerciseIdLevel)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchExerciseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExercisesApi - functional programming interface
 * @export
 */
export const ExercisesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExercisesApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve exercise by its ID
         * @param {GetExerciseByIdExerciseIdLevelParameter} exerciseIdLevel Exercise identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExerciseById(exerciseIdLevel: GetExerciseByIdExerciseIdLevelParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Exercise>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExerciseById(exerciseIdLevel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExercisesApi.getExerciseById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve exercise by its level/section/part/number
         * @param {GetExerciseByIdExerciseIdLevelParameter} exerciseIdLevel Exercise identifier
         * @param {Section} section Exercise section code
         * @param {Part} part Part inside of the section
         * @param {number} number Exercise number inside of the part
         * @param {Role} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExerciseByPosition(exerciseIdLevel: GetExerciseByIdExerciseIdLevelParameter, section: Section, part: Part, number: number, role: Role, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Exercise>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExerciseByPosition(exerciseIdLevel, section, part, number, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExercisesApi.getExerciseByPosition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing exercise
         * @param {GetExerciseByIdExerciseIdLevelParameter} exerciseIdLevel Exercise identifier
         * @param {PatchExerciseRequest} patchExerciseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchExercise(exerciseIdLevel: GetExerciseByIdExerciseIdLevelParameter, patchExerciseRequest: PatchExerciseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Exercise>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchExercise(exerciseIdLevel, patchExerciseRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExercisesApi.patchExercise']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExercisesApi - factory interface
 * @export
 */
export const ExercisesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExercisesApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve exercise by its ID
         * @param {GetExerciseByIdExerciseIdLevelParameter} exerciseIdLevel Exercise identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExerciseById(exerciseIdLevel: GetExerciseByIdExerciseIdLevelParameter, options?: RawAxiosRequestConfig): AxiosPromise<Exercise> {
            return localVarFp.getExerciseById(exerciseIdLevel, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve exercise by its level/section/part/number
         * @param {GetExerciseByIdExerciseIdLevelParameter} exerciseIdLevel Exercise identifier
         * @param {Section} section Exercise section code
         * @param {Part} part Part inside of the section
         * @param {number} number Exercise number inside of the part
         * @param {Role} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExerciseByPosition(exerciseIdLevel: GetExerciseByIdExerciseIdLevelParameter, section: Section, part: Part, number: number, role: Role, options?: RawAxiosRequestConfig): AxiosPromise<Exercise> {
            return localVarFp.getExerciseByPosition(exerciseIdLevel, section, part, number, role, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing exercise
         * @param {GetExerciseByIdExerciseIdLevelParameter} exerciseIdLevel Exercise identifier
         * @param {PatchExerciseRequest} patchExerciseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchExercise(exerciseIdLevel: GetExerciseByIdExerciseIdLevelParameter, patchExerciseRequest: PatchExerciseRequest, options?: RawAxiosRequestConfig): AxiosPromise<Exercise> {
            return localVarFp.patchExercise(exerciseIdLevel, patchExerciseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExercisesApi - object-oriented interface
 * @export
 * @class ExercisesApi
 * @extends {BaseAPI}
 */
export class ExercisesApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Retrieve exercise by its ID
     * @param {GetExerciseByIdExerciseIdLevelParameter} exerciseIdLevel Exercise identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExercisesApi
     */
    public getExerciseById(exerciseIdLevel: GetExerciseByIdExerciseIdLevelParameter, options?: RawAxiosRequestConfig) {
        return ExercisesApiFp(this.configuration).getExerciseById(exerciseIdLevel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve exercise by its level/section/part/number
     * @param {GetExerciseByIdExerciseIdLevelParameter} exerciseIdLevel Exercise identifier
     * @param {Section} section Exercise section code
     * @param {Part} part Part inside of the section
     * @param {number} number Exercise number inside of the part
     * @param {Role} role User role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExercisesApi
     */
    public getExerciseByPosition(exerciseIdLevel: GetExerciseByIdExerciseIdLevelParameter, section: Section, part: Part, number: number, role: Role, options?: RawAxiosRequestConfig) {
        return ExercisesApiFp(this.configuration).getExerciseByPosition(exerciseIdLevel, section, part, number, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Patch an existing exercise
     * @param {GetExerciseByIdExerciseIdLevelParameter} exerciseIdLevel Exercise identifier
     * @param {PatchExerciseRequest} patchExerciseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExercisesApi
     */
    public patchExercise(exerciseIdLevel: GetExerciseByIdExerciseIdLevelParameter, patchExerciseRequest: PatchExerciseRequest, options?: RawAxiosRequestConfig) {
        return ExercisesApiFp(this.configuration).patchExercise(exerciseIdLevel, patchExerciseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeedbacksApi - axios parameter creator
 * @export
 */
export const FeedbacksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new user feedback in English Exams Lab
         * @param {CreateFeedbackRequest} createFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeedback: async (createFeedbackRequest: CreateFeedbackRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFeedbackRequest' is not null or undefined
            assertParamExists('createFeedback', 'createFeedbackRequest', createFeedbackRequest)
            const localVarPath = `/feedbacks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFeedbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbacksApi - functional programming interface
 * @export
 */
export const FeedbacksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedbacksApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new user feedback in English Exams Lab
         * @param {CreateFeedbackRequest} createFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeedback(createFeedbackRequest: CreateFeedbackRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeedback(createFeedbackRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedbacksApi.createFeedback']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeedbacksApi - factory interface
 * @export
 */
export const FeedbacksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedbacksApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new user feedback in English Exams Lab
         * @param {CreateFeedbackRequest} createFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeedback(createFeedbackRequest: CreateFeedbackRequest, options?: RawAxiosRequestConfig): AxiosPromise<Feedback> {
            return localVarFp.createFeedback(createFeedbackRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedbacksApi - object-oriented interface
 * @export
 * @class FeedbacksApi
 * @extends {BaseAPI}
 */
export class FeedbacksApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Creates a new user feedback in English Exams Lab
     * @param {CreateFeedbackRequest} createFeedbackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbacksApi
     */
    public createFeedback(createFeedbackRequest: CreateFeedbackRequest, options?: RawAxiosRequestConfig) {
        return FeedbacksApiFp(this.configuration).createFeedback(createFeedbackRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LevelsApi - axios parameter creator
 * @export
 */
export const LevelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve the number of available exercises per part for a given level
         * @param {Level} level Exercise level code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exercisesPerLevel: async (level: Level, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'level' is not null or undefined
            assertParamExists('exercisesPerLevel', 'level', level)
            const localVarPath = `/levels/{Level}/exercises`
                .replace(`{${"Level"}}`, encodeURIComponent(String(level)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LevelsApi - functional programming interface
 * @export
 */
export const LevelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LevelsApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve the number of available exercises per part for a given level
         * @param {Level} level Exercise level code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exercisesPerLevel(level: Level, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: number; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exercisesPerLevel(level, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LevelsApi.exercisesPerLevel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LevelsApi - factory interface
 * @export
 */
export const LevelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LevelsApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve the number of available exercises per part for a given level
         * @param {Level} level Exercise level code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exercisesPerLevel(level: Level, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: number; }; }> {
            return localVarFp.exercisesPerLevel(level, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LevelsApi - object-oriented interface
 * @export
 * @class LevelsApi
 * @extends {BaseAPI}
 */
export class LevelsApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Retrieve the number of available exercises per part for a given level
     * @param {Level} level Exercise level code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public exercisesPerLevel(level: Level, options?: RawAxiosRequestConfig) {
        return LevelsApiFp(this.configuration).exercisesPerLevel(level, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LicensesApi - axios parameter creator
 * @export
 */
export const LicensesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Assign a license to a school user
         * @param {AssignLicenseRequest} assignLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignLicense: async (assignLicenseRequest: AssignLicenseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignLicenseRequest' is not null or undefined
            assertParamExists('assignLicense', 'assignLicenseRequest', assignLicenseRequest)
            const localVarPath = `/licenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignLicenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Give a counter of all bought and available licenses of a user in English Exams Lab
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countLicenses: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/licenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve license information by its ID
         * @param {string} licenseId License identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicense: async (licenseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseId' is not null or undefined
            assertParamExists('getLicense', 'licenseId', licenseId)
            const localVarPath = `/licenses/{LicenseId}`
                .replace(`{${"LicenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Revoke a license already assigned to a school user
         * @param {RevokeLicenseRequest} revokeLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeLicense: async (revokeLicenseRequest: RevokeLicenseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'revokeLicenseRequest' is not null or undefined
            assertParamExists('revokeLicense', 'revokeLicenseRequest', revokeLicenseRequest)
            const localVarPath = `/revoke-license`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revokeLicenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicensesApi - functional programming interface
 * @export
 */
export const LicensesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LicensesApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Assign a license to a school user
         * @param {AssignLicenseRequest} assignLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignLicense(assignLicenseRequest: AssignLicenseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<License>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignLicense(assignLicenseRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LicensesApi.assignLicense']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Give a counter of all bought and available licenses of a user in English Exams Lab
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countLicenses(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: LicenseDurationAvailability; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countLicenses(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LicensesApi.countLicenses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve license information by its ID
         * @param {string} licenseId License identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicense(licenseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<License>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicense(licenseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LicensesApi.getLicense']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Revoke a license already assigned to a school user
         * @param {RevokeLicenseRequest} revokeLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeLicense(revokeLicenseRequest: RevokeLicenseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeLicense(revokeLicenseRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LicensesApi.revokeLicense']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LicensesApi - factory interface
 * @export
 */
export const LicensesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LicensesApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Assign a license to a school user
         * @param {AssignLicenseRequest} assignLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignLicense(assignLicenseRequest: AssignLicenseRequest, options?: RawAxiosRequestConfig): AxiosPromise<License> {
            return localVarFp.assignLicense(assignLicenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Give a counter of all bought and available licenses of a user in English Exams Lab
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countLicenses(options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: LicenseDurationAvailability; }; }> {
            return localVarFp.countLicenses(options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve license information by its ID
         * @param {string} licenseId License identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicense(licenseId: string, options?: RawAxiosRequestConfig): AxiosPromise<License> {
            return localVarFp.getLicense(licenseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Revoke a license already assigned to a school user
         * @param {RevokeLicenseRequest} revokeLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeLicense(revokeLicenseRequest: RevokeLicenseRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.revokeLicense(revokeLicenseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicensesApi - object-oriented interface
 * @export
 * @class LicensesApi
 * @extends {BaseAPI}
 */
export class LicensesApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Assign a license to a school user
     * @param {AssignLicenseRequest} assignLicenseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensesApi
     */
    public assignLicense(assignLicenseRequest: AssignLicenseRequest, options?: RawAxiosRequestConfig) {
        return LicensesApiFp(this.configuration).assignLicense(assignLicenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Give a counter of all bought and available licenses of a user in English Exams Lab
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensesApi
     */
    public countLicenses(options?: RawAxiosRequestConfig) {
        return LicensesApiFp(this.configuration).countLicenses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve license information by its ID
     * @param {string} licenseId License identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensesApi
     */
    public getLicense(licenseId: string, options?: RawAxiosRequestConfig) {
        return LicensesApiFp(this.configuration).getLicense(licenseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Revoke a license already assigned to a school user
     * @param {RevokeLicenseRequest} revokeLicenseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensesApi
     */
    public revokeLicense(revokeLicenseRequest: RevokeLicenseRequest, options?: RawAxiosRequestConfig) {
        return LicensesApiFp(this.configuration).revokeLicense(revokeLicenseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Sign up a new school to English Exams Lab with an associated administrator
         * @summary Sign up a new school to English Exams Lab
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp: async (signUpRequest: SignUpRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpRequest' is not null or undefined
            assertParamExists('signUp', 'signUpRequest', signUpRequest)
            const localVarPath = `/login/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LambdaAPIKeyAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * Sign up a new school to English Exams Lab with an associated administrator
         * @summary Sign up a new school to English Exams Lab
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUp(signUpRequest: SignUpRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<School>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUp(signUpRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.signUp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * Sign up a new school to English Exams Lab with an associated administrator
         * @summary Sign up a new school to English Exams Lab
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp(signUpRequest: SignUpRequest, options?: RawAxiosRequestConfig): AxiosPromise<School> {
            return localVarFp.signUp(signUpRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * Sign up a new school to English Exams Lab with an associated administrator
     * @summary Sign up a new school to English Exams Lab
     * @param {SignUpRequest} signUpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public signUp(signUpRequest: SignUpRequest, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).signUp(signUpRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MockExamsApi - axios parameter creator
 * @export
 */
export const MockExamsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary List all mock exams submissions associated to a user
         * @param {Level} level Exercise level code
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMockExamSubmissions: async (level: Level, user?: string, role?: Role, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'level' is not null or undefined
            assertParamExists('listMockExamSubmissions', 'level', level)
            const localVarPath = `/mock-exams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Patch a started mock exam submission
         * @param {string} mockExamSubmissionId Mock exam submission identifier
         * @param {PatchMockExamSubmissionRequest} patchMockExamSubmissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMockExamSubmission: async (mockExamSubmissionId: string, patchMockExamSubmissionRequest: PatchMockExamSubmissionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mockExamSubmissionId' is not null or undefined
            assertParamExists('patchMockExamSubmission', 'mockExamSubmissionId', mockExamSubmissionId)
            // verify required parameter 'patchMockExamSubmissionRequest' is not null or undefined
            assertParamExists('patchMockExamSubmission', 'patchMockExamSubmissionRequest', patchMockExamSubmissionRequest)
            const localVarPath = `/mock-exams/{MockExamSubmissionId}`
                .replace(`{${"MockExamSubmissionId"}}`, encodeURIComponent(String(mockExamSubmissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchMockExamSubmissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Reset a started mock exam submission
         * @param {string} mockExamSubmissionId Mock exam submission identifier
         * @param {ResetMockExamSubmissionRequest} resetMockExamSubmissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetMockExamSubmission: async (mockExamSubmissionId: string, resetMockExamSubmissionRequest: ResetMockExamSubmissionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mockExamSubmissionId' is not null or undefined
            assertParamExists('resetMockExamSubmission', 'mockExamSubmissionId', mockExamSubmissionId)
            // verify required parameter 'resetMockExamSubmissionRequest' is not null or undefined
            assertParamExists('resetMockExamSubmission', 'resetMockExamSubmissionRequest', resetMockExamSubmissionRequest)
            const localVarPath = `/mock-exams/{MockExamSubmissionId}/reset`
                .replace(`{${"MockExamSubmissionId"}}`, encodeURIComponent(String(mockExamSubmissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetMockExamSubmissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Starts a new mock exam for the user
         * @param {StartMockExamRequest} startMockExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startMockExam: async (startMockExamRequest: StartMockExamRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startMockExamRequest' is not null or undefined
            assertParamExists('startMockExam', 'startMockExamRequest', startMockExamRequest)
            const localVarPath = `/mock-exams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startMockExamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MockExamsApi - functional programming interface
 * @export
 */
export const MockExamsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MockExamsApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary List all mock exams submissions associated to a user
         * @param {Level} level Exercise level code
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMockExamSubmissions(level: Level, user?: string, role?: Role, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MockExamSubmission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMockExamSubmissions(level, user, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MockExamsApi.listMockExamSubmissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Patch a started mock exam submission
         * @param {string} mockExamSubmissionId Mock exam submission identifier
         * @param {PatchMockExamSubmissionRequest} patchMockExamSubmissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMockExamSubmission(mockExamSubmissionId: string, patchMockExamSubmissionRequest: PatchMockExamSubmissionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MockExamSubmission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMockExamSubmission(mockExamSubmissionId, patchMockExamSubmissionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MockExamsApi.patchMockExamSubmission']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Reset a started mock exam submission
         * @param {string} mockExamSubmissionId Mock exam submission identifier
         * @param {ResetMockExamSubmissionRequest} resetMockExamSubmissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetMockExamSubmission(mockExamSubmissionId: string, resetMockExamSubmissionRequest: ResetMockExamSubmissionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetMockExamSubmission(mockExamSubmissionId, resetMockExamSubmissionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MockExamsApi.resetMockExamSubmission']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Starts a new mock exam for the user
         * @param {StartMockExamRequest} startMockExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startMockExam(startMockExamRequest: StartMockExamRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MockExamSubmission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startMockExam(startMockExamRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MockExamsApi.startMockExam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MockExamsApi - factory interface
 * @export
 */
export const MockExamsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MockExamsApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary List all mock exams submissions associated to a user
         * @param {Level} level Exercise level code
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMockExamSubmissions(level: Level, user?: string, role?: Role, options?: RawAxiosRequestConfig): AxiosPromise<Array<MockExamSubmission>> {
            return localVarFp.listMockExamSubmissions(level, user, role, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Patch a started mock exam submission
         * @param {string} mockExamSubmissionId Mock exam submission identifier
         * @param {PatchMockExamSubmissionRequest} patchMockExamSubmissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMockExamSubmission(mockExamSubmissionId: string, patchMockExamSubmissionRequest: PatchMockExamSubmissionRequest, options?: RawAxiosRequestConfig): AxiosPromise<MockExamSubmission> {
            return localVarFp.patchMockExamSubmission(mockExamSubmissionId, patchMockExamSubmissionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Reset a started mock exam submission
         * @param {string} mockExamSubmissionId Mock exam submission identifier
         * @param {ResetMockExamSubmissionRequest} resetMockExamSubmissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetMockExamSubmission(mockExamSubmissionId: string, resetMockExamSubmissionRequest: ResetMockExamSubmissionRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resetMockExamSubmission(mockExamSubmissionId, resetMockExamSubmissionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Starts a new mock exam for the user
         * @param {StartMockExamRequest} startMockExamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startMockExam(startMockExamRequest: StartMockExamRequest, options?: RawAxiosRequestConfig): AxiosPromise<MockExamSubmission> {
            return localVarFp.startMockExam(startMockExamRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MockExamsApi - object-oriented interface
 * @export
 * @class MockExamsApi
 * @extends {BaseAPI}
 */
export class MockExamsApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary List all mock exams submissions associated to a user
     * @param {Level} level Exercise level code
     * @param {string} [user] User identifier
     * @param {Role} [role] User role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MockExamsApi
     */
    public listMockExamSubmissions(level: Level, user?: string, role?: Role, options?: RawAxiosRequestConfig) {
        return MockExamsApiFp(this.configuration).listMockExamSubmissions(level, user, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Patch a started mock exam submission
     * @param {string} mockExamSubmissionId Mock exam submission identifier
     * @param {PatchMockExamSubmissionRequest} patchMockExamSubmissionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MockExamsApi
     */
    public patchMockExamSubmission(mockExamSubmissionId: string, patchMockExamSubmissionRequest: PatchMockExamSubmissionRequest, options?: RawAxiosRequestConfig) {
        return MockExamsApiFp(this.configuration).patchMockExamSubmission(mockExamSubmissionId, patchMockExamSubmissionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Reset a started mock exam submission
     * @param {string} mockExamSubmissionId Mock exam submission identifier
     * @param {ResetMockExamSubmissionRequest} resetMockExamSubmissionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MockExamsApi
     */
    public resetMockExamSubmission(mockExamSubmissionId: string, resetMockExamSubmissionRequest: ResetMockExamSubmissionRequest, options?: RawAxiosRequestConfig) {
        return MockExamsApiFp(this.configuration).resetMockExamSubmission(mockExamSubmissionId, resetMockExamSubmissionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Starts a new mock exam for the user
     * @param {StartMockExamRequest} startMockExamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MockExamsApi
     */
    public startMockExam(startMockExamRequest: StartMockExamRequest, options?: RawAxiosRequestConfig) {
        return MockExamsApiFp(this.configuration).startMockExam(startMockExamRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new order in English Exams Lab
         * @param {CreateOrderRequest} createOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: async (createOrderRequest: CreateOrderRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderRequest' is not null or undefined
            assertParamExists('createOrder', 'createOrderRequest', createOrderRequest)
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve order information by its ID
         * @param {string} orderId Order identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder: async (orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getOrder', 'orderId', orderId)
            const localVarPath = `/orders/{OrderId}`
                .replace(`{${"OrderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary List all orders of a user in English Exams Lab
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrders: async (maxResults?: number, nextToken?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing order
         * @param {string} orderId Order identifier
         * @param {PatchOrderRequest} patchOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrder: async (orderId: string, patchOrderRequest: PatchOrderRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('patchOrder', 'orderId', orderId)
            // verify required parameter 'patchOrderRequest' is not null or undefined
            assertParamExists('patchOrder', 'patchOrderRequest', patchOrderRequest)
            const localVarPath = `/orders/{OrderId}`
                .replace(`{${"OrderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new order in English Exams Lab
         * @param {CreateOrderRequest} createOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrder(createOrderRequest: CreateOrderRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrder(createOrderRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.createOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve order information by its ID
         * @param {string} orderId Order identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrder(orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrder(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.getOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary List all orders of a user in English Exams Lab
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrders(maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrders(maxResults, nextToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.listOrders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing order
         * @param {string} orderId Order identifier
         * @param {PatchOrderRequest} patchOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOrder(orderId: string, patchOrderRequest: PatchOrderRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOrder(orderId, patchOrderRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.patchOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new order in English Exams Lab
         * @param {CreateOrderRequest} createOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(createOrderRequest: CreateOrderRequest, options?: RawAxiosRequestConfig): AxiosPromise<Order> {
            return localVarFp.createOrder(createOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve order information by its ID
         * @param {string} orderId Order identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder(orderId: string, options?: RawAxiosRequestConfig): AxiosPromise<Order> {
            return localVarFp.getOrder(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary List all orders of a user in English Exams Lab
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrders(maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<OrdersListResponse> {
            return localVarFp.listOrders(maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing order
         * @param {string} orderId Order identifier
         * @param {PatchOrderRequest} patchOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrder(orderId: string, patchOrderRequest: PatchOrderRequest, options?: RawAxiosRequestConfig): AxiosPromise<Order> {
            return localVarFp.patchOrder(orderId, patchOrderRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Creates a new order in English Exams Lab
     * @param {CreateOrderRequest} createOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createOrder(createOrderRequest: CreateOrderRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createOrder(createOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve order information by its ID
     * @param {string} orderId Order identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getOrder(orderId: string, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getOrder(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary List all orders of a user in English Exams Lab
     * @param {number} [maxResults] Maximum number of results to return
     * @param {string | null} [nextToken] Next token for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public listOrders(maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).listOrders(maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Patch an existing order
     * @param {string} orderId Order identifier
     * @param {PatchOrderRequest} patchOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public patchOrder(orderId: string, patchOrderRequest: PatchOrderRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).patchOrder(orderId, patchOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This API is called asynchronously by the payment provider to notify English Exams Lab that PaymentId was successful.
         * @summary Confirms a new payment in English Exams Lab
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPayment: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('confirmPayment', 'body', body)
            const localVarPath = `/payment-confirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Creates a new payment in English Exams Lab
         * @param {CreatePaymentRequest} createPaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment: async (createPaymentRequest: CreatePaymentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPaymentRequest' is not null or undefined
            assertParamExists('createPayment', 'createPaymentRequest', createPaymentRequest)
            const localVarPath = `/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve payment information by its ID
         * @param {string} paymentId Payment identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayment: async (paymentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('getPayment', 'paymentId', paymentId)
            const localVarPath = `/payments/{PaymentId}`
                .replace(`{${"PaymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve all licenses associated to a payment by its ID
         * @param {string} paymentId Payment identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPaymentLicenses: async (paymentId: string, maxResults?: number, nextToken?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('listPaymentLicenses', 'paymentId', paymentId)
            const localVarPath = `/payments/{PaymentId}/licenses`
                .replace(`{${"PaymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary List all payments of a user in English Exams Lab
         * @param {LicenseDuration} [licenseDuration] License duration filter
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayments: async (licenseDuration?: LicenseDuration, maxResults?: number, nextToken?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (licenseDuration !== undefined) {
                localVarQueryParameter['licenseDuration'] = licenseDuration;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing payment
         * @param {string} paymentId Payment identifier
         * @param {PatchPaymentRequest} patchPaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPayment: async (paymentId: string, patchPaymentRequest: PatchPaymentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('patchPayment', 'paymentId', paymentId)
            // verify required parameter 'patchPaymentRequest' is not null or undefined
            assertParamExists('patchPayment', 'patchPaymentRequest', patchPaymentRequest)
            const localVarPath = `/payments/{PaymentId}`
                .replace(`{${"PaymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchPaymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * This API is called asynchronously by the payment provider to notify English Exams Lab that PaymentId was successful.
         * @summary Confirms a new payment in English Exams Lab
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmPayment(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmPayment(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.confirmPayment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Creates a new payment in English Exams Lab
         * @param {CreatePaymentRequest} createPaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPayment(createPaymentRequest: CreatePaymentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePayment200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPayment(createPaymentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.createPayment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve payment information by its ID
         * @param {string} paymentId Payment identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayment(paymentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayment(paymentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.getPayment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve all licenses associated to a payment by its ID
         * @param {string} paymentId Payment identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPaymentLicenses(paymentId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentLicensesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPaymentLicenses(paymentId, maxResults, nextToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.listPaymentLicenses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary List all payments of a user in English Exams Lab
         * @param {LicenseDuration} [licenseDuration] License duration filter
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPayments(licenseDuration?: LicenseDuration, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPayments(licenseDuration, maxResults, nextToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.listPayments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing payment
         * @param {string} paymentId Payment identifier
         * @param {PatchPaymentRequest} patchPaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPayment(paymentId: string, patchPaymentRequest: PatchPaymentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPayment(paymentId, patchPaymentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.patchPayment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * This API is called asynchronously by the payment provider to notify English Exams Lab that PaymentId was successful.
         * @summary Confirms a new payment in English Exams Lab
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPayment(body: object, options?: RawAxiosRequestConfig): AxiosPromise<Payment> {
            return localVarFp.confirmPayment(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Creates a new payment in English Exams Lab
         * @param {CreatePaymentRequest} createPaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment(createPaymentRequest: CreatePaymentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreatePayment200Response> {
            return localVarFp.createPayment(createPaymentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve payment information by its ID
         * @param {string} paymentId Payment identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayment(paymentId: string, options?: RawAxiosRequestConfig): AxiosPromise<Payment> {
            return localVarFp.getPayment(paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve all licenses associated to a payment by its ID
         * @param {string} paymentId Payment identifier
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPaymentLicenses(paymentId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<PaymentLicensesListResponse> {
            return localVarFp.listPaymentLicenses(paymentId, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary List all payments of a user in English Exams Lab
         * @param {LicenseDuration} [licenseDuration] License duration filter
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPayments(licenseDuration?: LicenseDuration, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<PaymentsListResponse> {
            return localVarFp.listPayments(licenseDuration, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing payment
         * @param {string} paymentId Payment identifier
         * @param {PatchPaymentRequest} patchPaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPayment(paymentId: string, patchPaymentRequest: PatchPaymentRequest, options?: RawAxiosRequestConfig): AxiosPromise<Payment> {
            return localVarFp.patchPayment(paymentId, patchPaymentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * This API is called asynchronously by the payment provider to notify English Exams Lab that PaymentId was successful.
     * @summary Confirms a new payment in English Exams Lab
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public confirmPayment(body: object, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).confirmPayment(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Creates a new payment in English Exams Lab
     * @param {CreatePaymentRequest} createPaymentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public createPayment(createPaymentRequest: CreatePaymentRequest, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).createPayment(createPaymentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve payment information by its ID
     * @param {string} paymentId Payment identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getPayment(paymentId: string, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).getPayment(paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve all licenses associated to a payment by its ID
     * @param {string} paymentId Payment identifier
     * @param {number} [maxResults] Maximum number of results to return
     * @param {string | null} [nextToken] Next token for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public listPaymentLicenses(paymentId: string, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).listPaymentLicenses(paymentId, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary List all payments of a user in English Exams Lab
     * @param {LicenseDuration} [licenseDuration] License duration filter
     * @param {number} [maxResults] Maximum number of results to return
     * @param {string | null} [nextToken] Next token for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public listPayments(licenseDuration?: LicenseDuration, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).listPayments(licenseDuration, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Patch an existing payment
     * @param {string} paymentId Payment identifier
     * @param {PatchPaymentRequest} patchPaymentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public patchPayment(paymentId: string, patchPaymentRequest: PatchPaymentRequest, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).patchPayment(paymentId, patchPaymentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PracticeTestsApi - axios parameter creator
 * @export
 */
export const PracticeTestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new practice test for the user
         * @param {CreatePracticeTestRequest} createPracticeTestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPracticeTest: async (createPracticeTestRequest: CreatePracticeTestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPracticeTestRequest' is not null or undefined
            assertParamExists('createPracticeTest', 'createPracticeTestRequest', createPracticeTestRequest)
            const localVarPath = `/practice-tests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPracticeTestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve practice test by its ID
         * @param {string} practiceTestId Practice Test identifier
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeTest: async (practiceTestId: string, user?: string, role?: Role, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceTestId' is not null or undefined
            assertParamExists('getPracticeTest', 'practiceTestId', practiceTestId)
            const localVarPath = `/practice-tests/{PracticeTestId}`
                .replace(`{${"PracticeTestId"}}`, encodeURIComponent(String(practiceTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary List all practice tests associated to a user
         * @param {Level} level Exercise level code
         * @param {Section} [section] Exercise section code
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPracticeTests: async (level: Level, section?: Section, user?: string, role?: Role, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'level' is not null or undefined
            assertParamExists('listPracticeTests', 'level', level)
            const localVarPath = `/practice-tests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Patch a started practice test
         * @param {string} practiceTestId Practice Test identifier
         * @param {PatchPracticeTestRequest} patchPracticeTestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPracticeTest: async (practiceTestId: string, patchPracticeTestRequest: PatchPracticeTestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceTestId' is not null or undefined
            assertParamExists('patchPracticeTest', 'practiceTestId', practiceTestId)
            // verify required parameter 'patchPracticeTestRequest' is not null or undefined
            assertParamExists('patchPracticeTest', 'patchPracticeTestRequest', patchPracticeTestRequest)
            const localVarPath = `/practice-tests/{PracticeTestId}`
                .replace(`{${"PracticeTestId"}}`, encodeURIComponent(String(practiceTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchPracticeTestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PracticeTestsApi - functional programming interface
 * @export
 */
export const PracticeTestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PracticeTestsApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new practice test for the user
         * @param {CreatePracticeTestRequest} createPracticeTestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPracticeTest(createPracticeTestRequest: CreatePracticeTestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPracticeTest(createPracticeTestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PracticeTestsApi.createPracticeTest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve practice test by its ID
         * @param {string} practiceTestId Practice Test identifier
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeTest(practiceTestId: string, user?: string, role?: Role, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPracticeTest(practiceTestId, user, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PracticeTestsApi.getPracticeTest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary List all practice tests associated to a user
         * @param {Level} level Exercise level code
         * @param {Section} [section] Exercise section code
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPracticeTests(level: Level, section?: Section, user?: string, role?: Role, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PracticeTest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPracticeTests(level, section, user, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PracticeTestsApi.listPracticeTests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Patch a started practice test
         * @param {string} practiceTestId Practice Test identifier
         * @param {PatchPracticeTestRequest} patchPracticeTestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPracticeTest(practiceTestId: string, patchPracticeTestRequest: PatchPracticeTestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPracticeTest(practiceTestId, patchPracticeTestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PracticeTestsApi.patchPracticeTest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PracticeTestsApi - factory interface
 * @export
 */
export const PracticeTestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PracticeTestsApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new practice test for the user
         * @param {CreatePracticeTestRequest} createPracticeTestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPracticeTest(createPracticeTestRequest: CreatePracticeTestRequest, options?: RawAxiosRequestConfig): AxiosPromise<PracticeTest> {
            return localVarFp.createPracticeTest(createPracticeTestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve practice test by its ID
         * @param {string} practiceTestId Practice Test identifier
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeTest(practiceTestId: string, user?: string, role?: Role, options?: RawAxiosRequestConfig): AxiosPromise<PracticeTest> {
            return localVarFp.getPracticeTest(practiceTestId, user, role, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary List all practice tests associated to a user
         * @param {Level} level Exercise level code
         * @param {Section} [section] Exercise section code
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPracticeTests(level: Level, section?: Section, user?: string, role?: Role, options?: RawAxiosRequestConfig): AxiosPromise<Array<PracticeTest>> {
            return localVarFp.listPracticeTests(level, section, user, role, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Patch a started practice test
         * @param {string} practiceTestId Practice Test identifier
         * @param {PatchPracticeTestRequest} patchPracticeTestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPracticeTest(practiceTestId: string, patchPracticeTestRequest: PatchPracticeTestRequest, options?: RawAxiosRequestConfig): AxiosPromise<PracticeTest> {
            return localVarFp.patchPracticeTest(practiceTestId, patchPracticeTestRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PracticeTestsApi - object-oriented interface
 * @export
 * @class PracticeTestsApi
 * @extends {BaseAPI}
 */
export class PracticeTestsApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Creates a new practice test for the user
     * @param {CreatePracticeTestRequest} createPracticeTestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeTestsApi
     */
    public createPracticeTest(createPracticeTestRequest: CreatePracticeTestRequest, options?: RawAxiosRequestConfig) {
        return PracticeTestsApiFp(this.configuration).createPracticeTest(createPracticeTestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve practice test by its ID
     * @param {string} practiceTestId Practice Test identifier
     * @param {string} [user] User identifier
     * @param {Role} [role] User role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeTestsApi
     */
    public getPracticeTest(practiceTestId: string, user?: string, role?: Role, options?: RawAxiosRequestConfig) {
        return PracticeTestsApiFp(this.configuration).getPracticeTest(practiceTestId, user, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary List all practice tests associated to a user
     * @param {Level} level Exercise level code
     * @param {Section} [section] Exercise section code
     * @param {string} [user] User identifier
     * @param {Role} [role] User role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeTestsApi
     */
    public listPracticeTests(level: Level, section?: Section, user?: string, role?: Role, options?: RawAxiosRequestConfig) {
        return PracticeTestsApiFp(this.configuration).listPracticeTests(level, section, user, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Patch a started practice test
     * @param {string} practiceTestId Practice Test identifier
     * @param {PatchPracticeTestRequest} patchPracticeTestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeTestsApi
     */
    public patchPracticeTest(practiceTestId: string, patchPracticeTestRequest: PatchPracticeTestRequest, options?: RawAxiosRequestConfig) {
        return PracticeTestsApiFp(this.configuration).patchPracticeTest(practiceTestId, patchPracticeTestRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SchoolSubdomainsApi - axios parameter creator
 * @export
 */
export const SchoolSubdomainsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve school subdomain images by its name
         * @param {string} name School subdomain name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchoolSubdomain: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getSchoolSubdomain', 'name', name)
            const localVarPath = `/subdomains/{Name}`
                .replace(`{${"Name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LambdaAPIKeyAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchoolSubdomainsApi - functional programming interface
 * @export
 */
export const SchoolSubdomainsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchoolSubdomainsApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve school subdomain images by its name
         * @param {string} name School subdomain name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchoolSubdomain(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subdomain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchoolSubdomain(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchoolSubdomainsApi.getSchoolSubdomain']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SchoolSubdomainsApi - factory interface
 * @export
 */
export const SchoolSubdomainsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchoolSubdomainsApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve school subdomain images by its name
         * @param {string} name School subdomain name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchoolSubdomain(name: string, options?: RawAxiosRequestConfig): AxiosPromise<Subdomain> {
            return localVarFp.getSchoolSubdomain(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchoolSubdomainsApi - object-oriented interface
 * @export
 * @class SchoolSubdomainsApi
 * @extends {BaseAPI}
 */
export class SchoolSubdomainsApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Retrieve school subdomain images by its name
     * @param {string} name School subdomain name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolSubdomainsApi
     */
    public getSchoolSubdomain(name: string, options?: RawAxiosRequestConfig) {
        return SchoolSubdomainsApiFp(this.configuration).getSchoolSubdomain(name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SchoolsApi - axios parameter creator
 * @export
 */
export const SchoolsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new school associated to a distributor of English Exams Lab
         * @param {CreateSchoolRequest} createSchoolRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchool: async (createSchoolRequest: CreateSchoolRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSchoolRequest' is not null or undefined
            assertParamExists('createSchool', 'createSchoolRequest', createSchoolRequest)
            const localVarPath = `/schools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSchoolRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Delete a school from English Exams Lab
         * @param {string} schoolId School identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchool: async (schoolId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('deleteSchool', 'schoolId', schoolId)
            const localVarPath = `/schools/{SchoolId}`
                .replace(`{${"SchoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve school by its ID
         * @param {string} schoolId School identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchool: async (schoolId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('getSchool', 'schoolId', schoolId)
            const localVarPath = `/schools/{SchoolId}`
                .replace(`{${"SchoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary List all schools in English Exams Lab
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSchools: async (maxResults?: number, nextToken?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/schools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing school
         * @param {string} schoolId School identifier
         * @param {PatchSchoolRequest} patchSchoolRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSchool: async (schoolId: string, patchSchoolRequest: PatchSchoolRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('patchSchool', 'schoolId', schoolId)
            // verify required parameter 'patchSchoolRequest' is not null or undefined
            assertParamExists('patchSchool', 'patchSchoolRequest', patchSchoolRequest)
            const localVarPath = `/schools/{SchoolId}`
                .replace(`{${"SchoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchSchoolRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchoolsApi - functional programming interface
 * @export
 */
export const SchoolsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchoolsApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new school associated to a distributor of English Exams Lab
         * @param {CreateSchoolRequest} createSchoolRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSchool(createSchoolRequest: CreateSchoolRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<School>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSchool(createSchoolRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchoolsApi.createSchool']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Delete a school from English Exams Lab
         * @param {string} schoolId School identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSchool(schoolId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSchool(schoolId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchoolsApi.deleteSchool']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve school by its ID
         * @param {string} schoolId School identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchool(schoolId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<School>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchool(schoolId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchoolsApi.getSchool']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary List all schools in English Exams Lab
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSchools(maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchoolsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSchools(maxResults, nextToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchoolsApi.listSchools']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing school
         * @param {string} schoolId School identifier
         * @param {PatchSchoolRequest} patchSchoolRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSchool(schoolId: string, patchSchoolRequest: PatchSchoolRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<School>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSchool(schoolId, patchSchoolRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchoolsApi.patchSchool']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SchoolsApi - factory interface
 * @export
 */
export const SchoolsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchoolsApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Creates a new school associated to a distributor of English Exams Lab
         * @param {CreateSchoolRequest} createSchoolRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchool(createSchoolRequest: CreateSchoolRequest, options?: RawAxiosRequestConfig): AxiosPromise<School> {
            return localVarFp.createSchool(createSchoolRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Delete a school from English Exams Lab
         * @param {string} schoolId School identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSchool(schoolId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSchool(schoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve school by its ID
         * @param {string} schoolId School identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchool(schoolId: string, options?: RawAxiosRequestConfig): AxiosPromise<School> {
            return localVarFp.getSchool(schoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary List all schools in English Exams Lab
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSchools(maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<SchoolsListResponse> {
            return localVarFp.listSchools(maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing school
         * @param {string} schoolId School identifier
         * @param {PatchSchoolRequest} patchSchoolRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSchool(schoolId: string, patchSchoolRequest: PatchSchoolRequest, options?: RawAxiosRequestConfig): AxiosPromise<School> {
            return localVarFp.patchSchool(schoolId, patchSchoolRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchoolsApi - object-oriented interface
 * @export
 * @class SchoolsApi
 * @extends {BaseAPI}
 */
export class SchoolsApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Creates a new school associated to a distributor of English Exams Lab
     * @param {CreateSchoolRequest} createSchoolRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolsApi
     */
    public createSchool(createSchoolRequest: CreateSchoolRequest, options?: RawAxiosRequestConfig) {
        return SchoolsApiFp(this.configuration).createSchool(createSchoolRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Delete a school from English Exams Lab
     * @param {string} schoolId School identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolsApi
     */
    public deleteSchool(schoolId: string, options?: RawAxiosRequestConfig) {
        return SchoolsApiFp(this.configuration).deleteSchool(schoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve school by its ID
     * @param {string} schoolId School identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolsApi
     */
    public getSchool(schoolId: string, options?: RawAxiosRequestConfig) {
        return SchoolsApiFp(this.configuration).getSchool(schoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary List all schools in English Exams Lab
     * @param {number} [maxResults] Maximum number of results to return
     * @param {string | null} [nextToken] Next token for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolsApi
     */
    public listSchools(maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig) {
        return SchoolsApiFp(this.configuration).listSchools(maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Patch an existing school
     * @param {string} schoolId School identifier
     * @param {PatchSchoolRequest} patchSchoolRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolsApi
     */
    public patchSchool(schoolId: string, patchSchoolRequest: PatchSchoolRequest, options?: RawAxiosRequestConfig) {
        return SchoolsApiFp(this.configuration).patchSchool(schoolId, patchSchoolRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve user statistics
         * @param {Level} level Exercise level code
         * @param {string} [user] User identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStatistics: async (level: Level, user?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'level' is not null or undefined
            assertParamExists('getUserStatistics', 'level', level)
            const localVarPath = `/user-statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve user statistics
         * @param {Level} level Exercise level code
         * @param {string} [user] User identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserStatistics(level: Level, user?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserStatistics(level, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatisticsApi.getUserStatistics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve user statistics
         * @param {Level} level Exercise level code
         * @param {string} [user] User identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStatistics(level: Level, user?: string, options?: RawAxiosRequestConfig): AxiosPromise<UserStatistics> {
            return localVarFp.getUserStatistics(level, user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Retrieve user statistics
     * @param {Level} level Exercise level code
     * @param {string} [user] User identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getUserStatistics(level: Level, user?: string, options?: RawAxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getUserStatistics(level, user, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SubmissionsApi - axios parameter creator
 * @export
 */
export const SubmissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Submits the solution to an exercise of English Exams Lab
         * @param {CreateSubmissionRequest} createSubmissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubmission: async (createSubmissionRequest: CreateSubmissionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSubmissionRequest' is not null or undefined
            assertParamExists('createSubmission', 'createSubmissionRequest', createSubmissionRequest)
            const localVarPath = `/submissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSubmissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve user submission by its id
         * @param {string} submissionId Submission identifier
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmission: async (submissionId: string, user?: string, role?: Role, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('getSubmission', 'submissionId', submissionId)
            const localVarPath = `/submissions/{SubmissionId}`
                .replace(`{${"SubmissionId"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve a list of all submissions by a user in a determined level and section
         * @param {Level} level Exercise level code
         * @param {Section} section Section inside of the level
         * @param {Part} [part] Part inside of the section
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubmissions: async (level: Level, section: Section, part?: Part, user?: string, role?: Role, maxResults?: number, nextToken?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'level' is not null or undefined
            assertParamExists('listSubmissions', 'level', level)
            // verify required parameter 'section' is not null or undefined
            assertParamExists('listSubmissions', 'section', section)
            const localVarPath = `/submissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }

            if (part !== undefined) {
                localVarQueryParameter['part'] = part;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Patch user submission by its id
         * @param {string} submissionId Submission identifier
         * @param {PatchSubmissionRequest} patchSubmissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSubmission: async (submissionId: string, patchSubmissionRequest: PatchSubmissionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('patchSubmission', 'submissionId', submissionId)
            // verify required parameter 'patchSubmissionRequest' is not null or undefined
            assertParamExists('patchSubmission', 'patchSubmissionRequest', patchSubmissionRequest)
            const localVarPath = `/submissions/{SubmissionId}`
                .replace(`{${"SubmissionId"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchSubmissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubmissionsApi - functional programming interface
 * @export
 */
export const SubmissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubmissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Submits the solution to an exercise of English Exams Lab
         * @param {CreateSubmissionRequest} createSubmissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubmission(createSubmissionRequest: CreateSubmissionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubmission(createSubmissionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionsApi.createSubmission']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve user submission by its id
         * @param {string} submissionId Submission identifier
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubmission(submissionId: string, user?: string, role?: Role, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubmission(submissionId, user, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionsApi.getSubmission']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve a list of all submissions by a user in a determined level and section
         * @param {Level} level Exercise level code
         * @param {Section} section Section inside of the level
         * @param {Part} [part] Part inside of the section
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSubmissions(level: Level, section: Section, part?: Part, user?: string, role?: Role, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmissionItemsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSubmissions(level, section, part, user, role, maxResults, nextToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionsApi.listSubmissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Patch user submission by its id
         * @param {string} submissionId Submission identifier
         * @param {PatchSubmissionRequest} patchSubmissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSubmission(submissionId: string, patchSubmissionRequest: PatchSubmissionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSubmission(submissionId, patchSubmissionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionsApi.patchSubmission']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SubmissionsApi - factory interface
 * @export
 */
export const SubmissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubmissionsApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Submits the solution to an exercise of English Exams Lab
         * @param {CreateSubmissionRequest} createSubmissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubmission(createSubmissionRequest: CreateSubmissionRequest, options?: RawAxiosRequestConfig): AxiosPromise<Submission> {
            return localVarFp.createSubmission(createSubmissionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve user submission by its id
         * @param {string} submissionId Submission identifier
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmission(submissionId: string, user?: string, role?: Role, options?: RawAxiosRequestConfig): AxiosPromise<Submission> {
            return localVarFp.getSubmission(submissionId, user, role, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve a list of all submissions by a user in a determined level and section
         * @param {Level} level Exercise level code
         * @param {Section} section Section inside of the level
         * @param {Part} [part] Part inside of the section
         * @param {string} [user] User identifier
         * @param {Role} [role] User role
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubmissions(level: Level, section: Section, part?: Part, user?: string, role?: Role, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<SubmissionItemsListResponse> {
            return localVarFp.listSubmissions(level, section, part, user, role, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Patch user submission by its id
         * @param {string} submissionId Submission identifier
         * @param {PatchSubmissionRequest} patchSubmissionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSubmission(submissionId: string, patchSubmissionRequest: PatchSubmissionRequest, options?: RawAxiosRequestConfig): AxiosPromise<Submission> {
            return localVarFp.patchSubmission(submissionId, patchSubmissionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubmissionsApi - object-oriented interface
 * @export
 * @class SubmissionsApi
 * @extends {BaseAPI}
 */
export class SubmissionsApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Submits the solution to an exercise of English Exams Lab
     * @param {CreateSubmissionRequest} createSubmissionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    public createSubmission(createSubmissionRequest: CreateSubmissionRequest, options?: RawAxiosRequestConfig) {
        return SubmissionsApiFp(this.configuration).createSubmission(createSubmissionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve user submission by its id
     * @param {string} submissionId Submission identifier
     * @param {string} [user] User identifier
     * @param {Role} [role] User role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    public getSubmission(submissionId: string, user?: string, role?: Role, options?: RawAxiosRequestConfig) {
        return SubmissionsApiFp(this.configuration).getSubmission(submissionId, user, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve a list of all submissions by a user in a determined level and section
     * @param {Level} level Exercise level code
     * @param {Section} section Section inside of the level
     * @param {Part} [part] Part inside of the section
     * @param {string} [user] User identifier
     * @param {Role} [role] User role
     * @param {number} [maxResults] Maximum number of results to return
     * @param {string | null} [nextToken] Next token for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    public listSubmissions(level: Level, section: Section, part?: Part, user?: string, role?: Role, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig) {
        return SubmissionsApiFp(this.configuration).listSubmissions(level, section, part, user, role, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Patch user submission by its id
     * @param {string} submissionId Submission identifier
     * @param {PatchSubmissionRequest} patchSubmissionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    public patchSubmission(submissionId: string, patchSubmissionRequest: PatchSubmissionRequest, options?: RawAxiosRequestConfig) {
        return SubmissionsApiFp(this.configuration).patchSubmission(submissionId, patchSubmissionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve a list of all exercises solved by the user in a determined level (exercise numbers only)
         * @param {string} userId User identifier
         * @param {Level} level Exercise level code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completedExercisesPerLevel: async (userId: string, level: Level, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('completedExercisesPerLevel', 'userId', userId)
            // verify required parameter 'level' is not null or undefined
            assertParamExists('completedExercisesPerLevel', 'level', level)
            const localVarPath = `/users/{UserId}/completed-exercises/{Level}`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"Level"}}`, encodeURIComponent(String(level)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Creates a new user inside of a school in English Exams Lab
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUserRequest: CreateUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createUser', 'createUserRequest', createUserRequest)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Delete a school user from English Exams Lab
         * @param {string} userId User identifier
         * @param {Role} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, role: Role, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('deleteUser', 'role', role)
            const localVarPath = `/users/{UserId}`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve user information by its ID
         * @param {string} userId User identifier
         * @param {Role} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, role: Role, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('getUser', 'role', role)
            const localVarPath = `/users/{UserId}`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Import teachers and students inside of classrooms of a school in English Exams Lab
         * @param {Array<ImportUser>} importUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importUsers: async (importUser: Array<ImportUser>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importUser' is not null or undefined
            assertParamExists('importUsers', 'importUser', importUser)
            const localVarPath = `/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve classrooms the user is enrolled in
         * @param {string} userId User identifier
         * @param {Role} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserClassrooms: async (userId: string, role: Role, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('listUserClassrooms', 'userId', userId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('listUserClassrooms', 'role', role)
            const localVarPath = `/users/{UserId}/classrooms`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary List all users in a school of English Exams Lab
         * @param {Role} role User role
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (role: Role, maxResults?: number, nextToken?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('listUsers', 'role', role)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing user
         * @param {string} userId User identifier
         * @param {Role} role User role
         * @param {PatchUserRequest} patchUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser: async (userId: string, role: Role, patchUserRequest: PatchUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchUser', 'userId', userId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('patchUser', 'role', role)
            // verify required parameter 'patchUserRequest' is not null or undefined
            assertParamExists('patchUser', 'patchUserRequest', patchUserRequest)
            const localVarPath = `/users/{UserId}`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Give here a longer description of the API
         * @summary Revoke a license already assigned to a school user
         * @param {RevokeLicenseRequest} revokeLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeLicense: async (revokeLicenseRequest: RevokeLicenseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'revokeLicenseRequest' is not null or undefined
            assertParamExists('revokeLicense', 'revokeLicenseRequest', revokeLicenseRequest)
            const localVarPath = `/revoke-license`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CognitoAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revokeLicenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve a list of all exercises solved by the user in a determined level (exercise numbers only)
         * @param {string} userId User identifier
         * @param {Level} level Exercise level code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completedExercisesPerLevel(userId: string, level: Level, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Array<number>; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completedExercisesPerLevel(userId, level, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.completedExercisesPerLevel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Creates a new user inside of a school in English Exams Lab
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUserRequest: CreateUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Delete a school user from English Exams Lab
         * @param {string} userId User identifier
         * @param {Role} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, role: Role, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.deleteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve user information by its ID
         * @param {string} userId User identifier
         * @param {Role} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, role: Role, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Import teachers and students inside of classrooms of a school in English Exams Lab
         * @param {Array<ImportUser>} importUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importUsers(importUser: Array<ImportUser>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importUsers(importUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.importUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve classrooms the user is enrolled in
         * @param {string} userId User identifier
         * @param {Role} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserClassrooms(userId: string, role: Role, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Classroom>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserClassrooms(userId, role, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.listUserClassrooms']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary List all users in a school of English Exams Lab
         * @param {Role} role User role
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(role: Role, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(role, maxResults, nextToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.listUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing user
         * @param {string} userId User identifier
         * @param {Role} role User role
         * @param {PatchUserRequest} patchUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUser(userId: string, role: Role, patchUserRequest: PatchUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUser(userId, role, patchUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.patchUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Give here a longer description of the API
         * @summary Revoke a license already assigned to a school user
         * @param {RevokeLicenseRequest} revokeLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeLicense(revokeLicenseRequest: RevokeLicenseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeLicense(revokeLicenseRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.revokeLicense']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Give here a longer description of the API
         * @summary Retrieve a list of all exercises solved by the user in a determined level (exercise numbers only)
         * @param {string} userId User identifier
         * @param {Level} level Exercise level code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completedExercisesPerLevel(userId: string, level: Level, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: Array<number>; }; }> {
            return localVarFp.completedExercisesPerLevel(userId, level, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Creates a new user inside of a school in English Exams Lab
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserRequest: CreateUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.createUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Delete a school user from English Exams Lab
         * @param {string} userId User identifier
         * @param {Role} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, role: Role, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, role, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve user information by its ID
         * @param {string} userId User identifier
         * @param {Role} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, role: Role, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.getUser(userId, role, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Import teachers and students inside of classrooms of a school in English Exams Lab
         * @param {Array<ImportUser>} importUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importUsers(importUser: Array<ImportUser>, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.importUsers(importUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Retrieve classrooms the user is enrolled in
         * @param {string} userId User identifier
         * @param {Role} role User role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserClassrooms(userId: string, role: Role, options?: RawAxiosRequestConfig): AxiosPromise<Array<Classroom>> {
            return localVarFp.listUserClassrooms(userId, role, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary List all users in a school of English Exams Lab
         * @param {Role} role User role
         * @param {number} [maxResults] Maximum number of results to return
         * @param {string | null} [nextToken] Next token for pagination
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(role: Role, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<UsersListResponse> {
            return localVarFp.listUsers(role, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Patch an existing user
         * @param {string} userId User identifier
         * @param {Role} role User role
         * @param {PatchUserRequest} patchUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser(userId: string, role: Role, patchUserRequest: PatchUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.patchUser(userId, role, patchUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Give here a longer description of the API
         * @summary Revoke a license already assigned to a school user
         * @param {RevokeLicenseRequest} revokeLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeLicense(revokeLicenseRequest: RevokeLicenseRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.revokeLicense(revokeLicenseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Give here a longer description of the API
     * @summary Retrieve a list of all exercises solved by the user in a determined level (exercise numbers only)
     * @param {string} userId User identifier
     * @param {Level} level Exercise level code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public completedExercisesPerLevel(userId: string, level: Level, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).completedExercisesPerLevel(userId, level, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Creates a new user inside of a school in English Exams Lab
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(createUserRequest: CreateUserRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Delete a school user from English Exams Lab
     * @param {string} userId User identifier
     * @param {Role} role User role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(userId: string, role: Role, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUser(userId, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve user information by its ID
     * @param {string} userId User identifier
     * @param {Role} role User role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(userId: string, role: Role, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(userId, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Import teachers and students inside of classrooms of a school in English Exams Lab
     * @param {Array<ImportUser>} importUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public importUsers(importUser: Array<ImportUser>, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).importUsers(importUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Retrieve classrooms the user is enrolled in
     * @param {string} userId User identifier
     * @param {Role} role User role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listUserClassrooms(userId: string, role: Role, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).listUserClassrooms(userId, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary List all users in a school of English Exams Lab
     * @param {Role} role User role
     * @param {number} [maxResults] Maximum number of results to return
     * @param {string | null} [nextToken] Next token for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listUsers(role: Role, maxResults?: number, nextToken?: string | null, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).listUsers(role, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Patch an existing user
     * @param {string} userId User identifier
     * @param {Role} role User role
     * @param {PatchUserRequest} patchUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchUser(userId: string, role: Role, patchUserRequest: PatchUserRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).patchUser(userId, role, patchUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Give here a longer description of the API
     * @summary Revoke a license already assigned to a school user
     * @param {RevokeLicenseRequest} revokeLicenseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public revokeLicense(revokeLicenseRequest: RevokeLicenseRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).revokeLicense(revokeLicenseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}




/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-west-3",
  aws_cognito_region: "eu-west-3",
  aws_user_pools_id: "eu-west-3_3G0W66H3p",
  aws_user_pools_web_client_id: "ud3hbpfi5m910q0ln3cn1gqk",
  oauth: {
    domain: "auth.eeltesting.com",
    scope: ["email", "profile", "openid"],
    redirectSignIn: "http://localhost:3000/",
    redirectSignOut: "http://localhost:3000/",
    responseType: "code",
    logoutUri: "http://localhost:3000/",
  },
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: "6",
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;

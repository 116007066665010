import { Amplify } from "aws-amplify";
import { createRoot } from "react-dom/client";
import { Application } from "./components/Application";
import { Providers } from "./components/Providers";
import "./i18n/config";
import "./index.css";
import { captureFederationErrorDescription } from "./federationErrorHandler";

const configFileName = process.env.REACT_APP_AMPLIFY_EXPORTS;

const getConfigModule = () => {
  switch (configFileName) {
    case "aws-exports-test":
      return require("./aws-exports-test");
    case "aws-exports-dev":
      return require("./aws-exports-dev");
    case "aws-exports-prod":
      return require("./aws-exports-prod");
    default:
      throw new Error(`Invalid config file name: ${configFileName}`);
  }
};

const configModule = getConfigModule();

captureFederationErrorDescription();

https: Amplify.configure(configModule.default);

if (process.env.REACT_APP_ENVIRONMENT === "prod") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const container = document.getElementById("root")!;
const root = createRoot(container);

// TODO: Change to this when we migrate to React 19
// const root = createRoot(container, {
//   // Callback called when an error is thrown and not caught by an ErrorBoundary.
//   onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
//     console.warn('Uncaught error', error, errorInfo.componentStack);
//   }),
//   // Callback called when React catches an error in an ErrorBoundary.
//   onCaughtError: Sentry.reactErrorHandler(),
//   // Callback called when React automatically recovers from errors.
//   onRecoverableError: Sentry.reactErrorHandler(),
// });

root.render(
  <Providers>
    <Application />
  </Providers>,
);
export { checkIfCorrect } from "./utils/Questions";
